import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";

export const PHOTO_TYPES = [
  {id: 'CAVALO', label: 'Placa da frente'},
  {id: 'CARRETA', label: 'Placa de trás'},
  {id: 'CONTAINER_TOP', label: 'Container - Topo'},
  {id: 'CONTAINER_LEFT', label: 'Container - Esquerda'},
  {id: 'CONTAINER_RIGHT', label: 'Container - Direita'},
  {id: 'CONTAINER_BACK', label: 'Container - Fundo'},
];

const PhotoTypeSelector = ({
  required = false,
  title = "Tipo de foto",
  setValue = PHOTO_TYPES,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    title={title}
    required={required}
    label="label"
    setValue={setValue}
    items={PHOTO_TYPES}
    {...props}
  />
}

export default PhotoTypeSelector;
