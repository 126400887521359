import OptionColumn from "../../components/table/OptionColumn";
import {dayMonthYearAtTimeWithSeconds} from "../../shared/utils/dates";
import {TableButton} from "../../components/button/TableButton";
import EventDetails from "./EventDetails";
import {DateRangeColumnFilter, SelectColumnFilter} from "../../components/table/TableFilter";
import GateSelector from "./component/GateSelector";

const OCREventListSchema = () => {

  const endpoint = 'gtt-cflow-ocr/v1/events';

  return {
    title: 'Lista de Eventos - OCR',

    getEndpoint: () => endpoint,

    renderRowSubComponent: ({row}) => {
      return <EventDetails row={row.original}  />
    },

    getHeader: () => {
      return {
        showNewButton:false,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Data",
          accessor: "intervalDate",
          Filter: DateRangeColumnFilter,
          Cell: ({ row }) => dayMonthYearAtTimeWithSeconds(row.original?.createdAt)
        },
        {
          Header: "Gate",
          accessor: "gate.id",
          Filter:SelectColumnFilter,
          CustomSelector: GateSelector,
          Cell: ({ row }) => row.original.gate.name,
        },
        {
          Header: "Valores reconhecidos",
          filter: false,
          Cell: ({ row }) => row.original.photos?.map(x => x.recognitions?.map(y => <p>{y.value == ""? "****" : y.value}</p>)),
        },

        OptionColumn({
          children: ({row}) => <TableButton icon={row.isExpanded ? "fas fa-minus" : "fas fa-plus"}  to={""} {...row.getToggleRowExpandedProps()} title={row.isExpanded ? 'Fechar' : 'Foto'}/>,
          canShowEditButton: () => false,
          showDelete: false,
          width: 80,
          minWidth: 80,
          maxWidth: 80,
        })
      ]
    },

  }
}

export default OCREventListSchema;

