import {BaseSelectorReadonlyToInputV2} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const DocumentSelector = ({
  required = false,
  defaultValue,
  title = "Documento",
  setValue = null,
  ...props
}) => {

  const mapItems = (item) => {
    return {
      ...item,
      id: item.identificationDocument.value,
      name: (item.identificationDocument.id + ' - ' + item.identificationDocument.value)
    }
  }

  const getUrl = (filterValue) => {
    return `gtt-access-control/v1/persons?sort=name,ASC&documentOrName=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="name"
    getUrl={getUrl}
    setValue={setValue}
    showCreateButton={true}
    pathToCreate={ROUTE_PATH.PERSON_FORM_LIST}
    mapItems={mapItems}
    {...props}
  />
}

export default DocumentSelector;
