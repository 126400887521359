import React from 'react'

function PropertyAndValueText({ property, value, propertyClassName, valueClassName, containerClassName }) {
  return (
    <div className={`row align-items-center ${containerClassName}`}>
      <div className={`text-bold ${propertyClassName}`}>{property}&nbsp;</div><div className={valueClassName}>{value}</div>
    </div>
  )
}

export default PropertyAndValueText
