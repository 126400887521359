import {BaseSelectorReadonlyToInputV2} from "../../../components";

const VehicleSelector = ({
  required = false,
  defaultValue,
  title = "Veículo",
  setValue = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `gtt-access-control/v1/vehicles?sort=name,ASC&frontPlate=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="frontPlate"
    getUrl={getUrl}
    setValue={setValue}
    showCreateButton={false}
    {...props}
  />

}

export default VehicleSelector;
