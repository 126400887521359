import SupplierSelector from "../supplier/component/SupplierSelector";

const PreScheduleFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/pre-schedules/';

  const dateNow = new Date();

  const startDate = (date, seconds, ms) => {
    dateNow.setSeconds(seconds, ms);
    return dateNow
  }
  const finishDate = (date, seconds, ms, hours,) => {
    dateNow.setHours(dateNow.getHours() + hours);
    dateNow.setSeconds(seconds, ms);
    return dateNow;
  }

  return {
    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },

    getSchema() {
      return {
        title: 'Agendamentos',
        type: 'object',
        required: [
          'company',
          'initialDate',
          'finishDate',
        ],
        properties: {
          company: {
            type: 'object',
            title: 'Empresa',
            properties: {
              id: {type: 'string'},
              name: {type: 'string', title: 'Nome'},
            },
          },
          type: {
            type: 'boolean',
            title: 'Entrega ou retirada?',
            enumNames: ['Entrega', 'Retirada'],
          },
          initialDate: {
            type: 'string',
            default: startDate(dateNow, 0, 0).toISOString(),
            title: 'Data e horário inicial de chegada',
            format: 'date-time',
          },
          finishDate: {
            type: 'string',
            default: finishDate(dateNow, 0, 0, 1).toISOString(),
            title: 'Data e horário limite de chegada',
            format: 'date-time',
          },
          active: {
            type: "boolean",
            default: true,
            title: "Agendamento ativo"
          },
          used: {
            type: 'boolean',
            default: false,
            title: 'Agendamento já utilizado?',
            enumNames: ['Sim', 'Não'],
            readOnly: true
          },
          uniqueScheduleId: {
            type: 'string',
            title: 'Código do agendamento',
            readOnly: true
          },
        },
      };
    },

    getUiSchema: () => {
      return {
        company: {
          'ui:field': (props) => {
            return (
              <SupplierSelector
                disabled={props.disabled}
                required={props.required}
                title={props.schema.title}
                value={props.formData}
                setValue={props.onChange}
              />
            );
          },
        },
        type: {
          'ui:widget': 'select',
        },
        active: {
          "ui:widget": "checkbox"
        },
        uniqueScheduleId: {
          "ui:placeholder": 'Será gerado após criar o pré agendamento.',
        }
      };
    },
  };
};

export default PreScheduleFormSchema;
