import { BaseSelectorReadonlyToInputV2 } from "../../../components";

const CompanySelector = ({
  required = false,
  defaultValue,
  title = "Empresa",
  setValue = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `gtt-auth/v1/companies?sort=suppliers,ASC&name=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="name"
    getUrl={getUrl}
    setValue={setValue}
    {...props}
  />

}

export default CompanySelector;
