import OptionColumn from "../../components/table/OptionColumn";
import {formatByIdAndMatch, ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {SelectColumnFilter} from "../../components/table/TableFilter";
import UserStatusSelector from "./component/UserStatusSelector";
import PendingPersonSelector from "./component/PendingPersonSelector";
import DocumentSelector from "./component/DocumentSelector";
import {Link} from "react-router-dom";
import CompanySelector from "../integration-config/component/CompanySelector";

const PersonListSchema = () => {

  const endpoint = 'gtt-access-control/v1/persons/';

  return {
    title: 'Lista de Pessoas',

    getEndpoint: () => endpoint,

    showPDFButton: true,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.PERSON_EXISTS_FORM + '?wizzard=true',
      }
    },

    getColumnsPDF: () => {
      return [
        {
          Header: "Nome",
          accessor: "name",
        },
        {
          Header: "Documento",
          accessor: "identificationDocument.value",
        },
        {
          Header: "Estado",
          accessor: 'enable',
        },
        {
          Header: "Empresa(s)",
          accessor:"accessCompaniesNames",
        },
        {
          Header: "Acesso Interno",
          accessor:"canDoInternalAccess",
        },
        {
          Header: "Documentos conferidos por",
          accessor: 'approved',
        },
        {
          Header: "Validade de Acesso",
          accessor: 'validadeAcessoInterno',
        },
      ]},

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "name",
          Cell: ({row}) => <Link to={formatByIdAndMatch({id: row.original.id}, ROUTE_PATH.PERSON_PROFILE_PAGE)}>{row.original.name}</Link>
        },
        {
          Header: "Documento",
          accessor: "identificationDocument.value",
          Filter: SelectColumnFilter,
          CustomSelector: DocumentSelector,
          Cell: ({row: {original}}) => {
            return original.identificationDocument ? original.identificationDocument.id + ' - ' + original.identificationDocument.value : null
          }
        },
        {
          Header: "Estado",
          sortable: false,
          export: false,
          accessor: 'isEnable',
          Filter: SelectColumnFilter,
          CustomSelector: UserStatusSelector,
          Cell: ({row: {original}}) => original.enable ? 'Ativo' : 'Bloqueado'
        },
        {
          Header: "Empresa(s)",
          Filter: SelectColumnFilter,
          CustomSelector: CompanySelector,
          Cell: ({row}) => {
            return row.original.accessCompany ? row.original.accessCompany?.company?.name : row.original.accessCompanies.map((x,company )=> <li key={company}>{x.company?.name}</li>)
          }
        },
        {
          Header: "Documentos conferidos por:",
          accessor: 'pendingApproved',
          Filter: SelectColumnFilter,
          CustomSelector: PendingPersonSelector,
          Cell: ({row: {original}}) => {
            return original.approved ? original.approved.name : "NÃO CONFERIDO"
          }
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.PERSON_FORM_LIST,
          showAddPhoto: true,
          canShowApproveButton: ({original}) => !original?.approved,
          showBlock: true,
          getActiveStatus: ({original}) => {
            if (original.enable === false) {
              return false;
            }
            return original.accessCompany ? !original.accessCompany.personBlock : true;
          },
          entity: 'Pessoa',
          width: 300,
          minWidth: 300,
          maxWidth: 300,
        })
      ]
    },

  }
}

export default PersonListSchema;

