import Notify from "../../shared/common/notify/Notify";
import ImageCrop from "../../components/image-crop/ImageCrop";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const PersonPhotoFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/persons-photos/persons/'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },

    onNotFoundError(e) {
      Notify.warning('Está pessoa ainda não possui uma foto!');
    },

    redirectOnSave(history, entity, match, {currentUser}) {
      const params = new URLSearchParams(history.location.search)
      console.log("currentUser", currentUser)
      if (!params.has('wizzard')) {
        history.goBack();
        return;
      }
      if (currentUser.super && !entity.approved) {
        history.replace(ROUTE_PATH.APPROVE_PERSON_FORM + match.params?.id + '?wizzard=true')
        return;
      }
      history.replace(ROUTE_PATH.INTERNAL_PERSON_FORM + match.params?.id + '?wizzard=true');
    },

    formatObjectToGet(formData) {
      console.log(formData)
      formData.photoPreview = formData.base64;
      return formData;
    },

    getSchema() {
      return {
        "title": "Cadastro de foto ",
        "description": "UPLOAD DE FOTO",
        "type": "object",
        "required": ["base64"],
        "properties": {
          "photoPreview": {
            "title": "Foto Atual", "type": "string",
          },
          "helpText": {
            "title": "A null field",
            "description": "A foto deve respeitar os seguintes parâmetros: Resolução mínima: 150 × 300 pixels (L × A). Resolução Máxima: 600 × 1200 pixels (L × A). A altura não deve exceder duas vezes a largura. Tamanho máximo de arquivo: 2MB. Recomenda-se a utilização de imagens com resolução superior a 500 × 500 pixels (L × A) onde o rosto não deve ocupar mais de 2/3 da área total da imagem.",
            "type": "null"
          },
          "base64": {
            "type": "string"
          },
          "person": {
            "type": "object", "properties": {
              "id": {
                "type": "string"
              }
            }
          },

        }
      }
    },

    getUiSchema: () => {
      return {
        "base64": {
          "ui:field": (props) => {
            return (<ImageCrop setValue={props.onChange}/>)
          },
        }, "person": {
          "ui:widget": "hidden",
        }, "photoPreview": {
          "ui:field": (props) => {
            return (<div className="text-center">
                  <img alt="Foto da pessoa" className={"person-photo-image"}
                       src={props.formData}/>
                </div>

            );
          }
        }
      }
    }
  }
}

export default PersonPhotoFormSchema;


