import {dayMonthYearAtTimeWithSeconds} from "../../shared/utils/dates";
import {SelectColumnFilter} from "../../components/table/TableFilter";
import PersonSelector from "../person/component/PersonSelector";
import AccessTypeSelector from "../access/component/AccessTypeSelector";
import {AccessTypeMessageGrid} from "../access-events/AccessEventsListSchema";

const DeviceEventsListSchema = () => {

  const endpoint = 'gtt-access-control/v1/access-events';

  return {
    getEndpoint: ({history}) => {
      const search = new URLSearchParams(history.location.search)

      return endpoint + '?device.id=' + search.get('deviceId') + '&';
    },

    renderRowSubComponent: () => {
      return <div>Denner</div>
    },

    getHeader: () => {
      return {
        showNewButton: false,
        showBackButton: true
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Data",
          accessor: "createdAt",
          filter: false,
          Cell: ({row}) => dayMonthYearAtTimeWithSeconds(row.original?.createdAt)
        },
        {
          Header: "Pessoas",
          accessor: "person",
          Filter: SelectColumnFilter,
          CustomSelector: PersonSelector,
          Cell: ({row}) => row.original.person ? (row.original.person.identificationDocument.id + ' - '
            + row.original.person.identificationDocument.value + ' - ' + row.original.person.name) : "Não identificado"
        },
        {
          Header: "Tipo de Acesso",
          accessor: "accessType",
          urlValue: "accessEvent",
          Filter: SelectColumnFilter,
          CustomSelector: AccessTypeSelector,
          Cell: ({row}) => AccessTypeMessageGrid(row.original.accessType)
        },
        {
          Header: "Dispositivo",
          accessor: "device.id",
          Filter: false,
          Cell: ({row}) => row.original?.device?.name || null
        },
      ]
    },
  }
}

export default DeviceEventsListSchema;

