import React, {useEffect, useState} from "react";
import "./ImageCrop.css"

import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import getCroppedImg, {getImageSize} from "./utils/cropimage";
import {Button} from "react-bootstrap";
import WebcamCapture from "../Webcam";
import Notify from "../../shared/common/notify/Notify";
import DeviceWebcam from "../DeviceWebcam";

const ImageCrop = ({setValue}) => {
  const inputRef = React.useRef();

  const triggerFileSelectPopup = () => inputRef.current.click();

  const [image, setImage] = useState(null);
  const [useWebCam, setHasUseWebCam] = useState(false);
  const [useDevice, setHasUseDevice] = useState(false);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    selectCroppedImage();
  }, [croppedArea])

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onPhotoSelected = (photo) => {
    setHasUseWebCam(false);
    setHasUseDevice(false);

    setTimeout(() => {
      document.getElementById("photo").scrollIntoView({behavior: 'smooth'});
    }, 200)

    if (getImageSize(photo) > 2097152) {
      Notify.error('O tamanho da imagem selecionada excedeu o limite permitido! Diminua a qualidade da imagem da câmera e tente novamente.')
    } else {
      setImage(photo);
    }
  }

  const onSelectFile = (event) => {
    setTimeout(() => {
      document.getElementById("photo").scrollIntoView({behavior: 'smooth'});
    }, 200)
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].size > 2097152) {
        Notify.error('O tamanho da imagem selecionada excedeu o limite permitido! Faça o upload de uma imagem com tamanho máximo de 2MB.')
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.addEventListener("load", () => {
          setImage(reader.result);
        });
      }
    }
  };

  const startWebCam = () => {
    if(useWebCam){
      setHasUseWebCam(false)
    }else {
      setHasUseWebCam(true);
      setHasUseDevice(false)
    }
    if (!useWebCam) {
      setTimeout(() => {
        document.getElementById("webcamScreen").scrollIntoView({behavior: 'smooth'});
      }, 200)
    }
  };

  const listDevices = () => {
    if(useDevice){
      setHasUseDevice(false)
    }else {
      setHasUseDevice(true);
      setHasUseWebCam(false)
    }
  };

  const selectCroppedImage = async () => {
    const data = await getCroppedImg(image, croppedArea)
    setValue(data);

  };
  return (
    <div className='container-image-crop'>

      <div className='container-buttons'>
        <input
          type='file'
          accept='image/*'
          ref={inputRef}
          onChange={onSelectFile}
          style={{display: "none"}}
        />
        <Button
          className={"primary mb-3"}
          onClick={triggerFileSelectPopup}
        >
          Nova foto
        </Button>

        <Button
          className={"primary ml-1 mb-3"}
          type="button"
          onClick={startWebCam}
        >
          Usar WebCam
        </Button>
        <Button
          className={"primary ml-1 mb-3"}
          type="button"
          onClick={listDevices}
        >
          Usar Leitora
        </Button>
      </div>
      {useWebCam && <WebcamCapture onLoadPhoto={onPhotoSelected}/>}
      {useDevice && <DeviceWebcam onLoadPhoto={onPhotoSelected}/>}
      {image && (
        <>
          <div id="photo" className='container-cropper'>
            <div className='slider'>
              <Slider
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>

            <div className='cropper'>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={0.7}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ImageCrop;
