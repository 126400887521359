import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const RoleListSchema = () => {
  const endpoint = "gtt-auth/v1/authorities/";

  return {
    title: "Lista de Cargos",

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.ROLE_FORM_LIST,
      };
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "name",
        },
        {
          Header: "Descrição",
          accessor: "description",
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.ROLE_FORM_LIST,
        }),
      ];
    },
  };
};

export default RoleListSchema;
