import HttpRequest from "../../shared/common/http/HttpRequest";

const getSortList = ({defaultSort}, sortBy) => {
  if (sortBy && sortBy.length > 0) {
    return sortBy[0];
  }
  return defaultSort;
}

export const doHttpGetListTable = async (entitySchema, {filters, pageSize, pageIndex, sortBy}, props) => {
  const url = entitySchema.getEndpoint(props);
  if (url) {
    const urlWithSortAndPagination = url.indexOf('?') === -1 ? `${url}?` : url;
    const {id, desc} = getSortList(entitySchema, sortBy);
    const direction = desc ? 'DESC' : 'ASC';
    const query = new URLSearchParams();
    filters && filters.forEach((x)=>
    {
      if (x.id === "duracao") {
        query.append("_startDate", x.value[0].toISOString());
        query.append("_endDate", x.value[1].toISOString());
      } else {
        query.append(x.id, x.value);
      }
    })
    query.append('page', pageIndex);
    query.append('size', pageSize);
    return doRequest(`${urlWithSortAndPagination}${query.toString()}&sort=${id},${direction}`, pageSize);
  }
}

const doRequest = async (url, pageSize) => {
  const response = await HttpRequest.get(url);
  const {content, totalElements} = response;
  return {content, pageCount: Math.ceil(totalElements / pageSize)};
}
