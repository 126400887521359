const RoleFormSchema = () => {
  const baseEndpoint = "gtt-auth/v1/authorities/";
  return {
    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    isNew({version}) {
      return version === undefined;
    },

    getSaveUrlV2({_id, version}) {
      if (version >= 0) {
        return `${baseEndpoint}${_id || ""}`;
      } else {
        return `${baseEndpoint}`;
      }
    },

    getSchema() {
      return {
        title: "Cargo",
        type: "object",
        required: ["id", "description"],
        properties: {
          id: {
            type: "string", title: "Nome",
          },
          description: {
            type: "string", title: "Descrição",
          },
        },
      };
    },
  };
};

export default RoleFormSchema;
