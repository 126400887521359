import OptionColumn from "../../components/table/OptionColumn";
import { SelectColumnFilter } from "../../components/table/TableFilter";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import RoleSelector from "../role/component/RoleSelector";

const ProfileListSchema = () => {
  const endpoint = 'gtt-auth/v1/profiles';
  return {
    title: 'Lista de Perfils de Acesso',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.PROFILE_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "name"
        },
        {
          Header: "Permissão",
          accessor: "authorities",
          Filter: SelectColumnFilter,
          CustomSelector: RoleSelector,
          Cell: ({row}) => {
            console.log(row)
            return row.original.authorities ? row.original.authorities.map(x => <li>{x.id}</li>) : ''}
        },
        OptionColumn({
          showDelete: true,
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.PROFILE_FORM
        })
      ]
    },

  }
}

export default ProfileListSchema;

