import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {DateRangeColumnFilter, SelectColumnFilter} from "../../components/table/TableFilter";
import ScheduleTypeSelector from "../schedule/component/ScheduleTypeSelector";
import {dayMonthYearAtTime} from "../../shared/utils/dates";
import OptionColumn from "../../components/table/OptionColumn";

const PreScheduleListSchema = () => {

  const endpoint = 'gtt-access-control/v1/pre-schedules';

  const scheduleEndpoint = `/list/schedules?scgEventId=`;

  return {
    title: 'Lista de Pré Agendamentos',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.PRE_SCHEDULE_FORM,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Empresa",
          accessor: "company.name",
          Cell: ({row}) => row.original.company.name,
        },
        {
          Header: "Tipo de agendamento",
          accessor: "type",
          width: 80,
          minWidth: 80,
          maxWidth: 80,
          Filter: SelectColumnFilter,
          CustomSelector: ScheduleTypeSelector,
          Cell: ({row: {original}}) =>
            original.type ? "Entrega" : "Retirada",
        },
        {
          Header: "Data do pré-agendamento",
          sortable: false,
          export: false,
          accessor: 'initialDate',
          Filter: DateRangeColumnFilter,
          Cell: ({row}) =>
            'De ' + (row.original.initialDate && dayMonthYearAtTime(row.original.initialDate)) + ' até ' + (row.original.initialDate && dayMonthYearAtTime(row.original.finishDate)),
        },
        {
          Header: "Código do Agendamento",
          accessor: "uniqueScheduleId",
          Cell: ({row}) => row.original.uniqueScheduleId,
        },
        {
          Header: "Status do agendamento",
          Filter: false,
          Cell: ({row: {original}}) => {
            if (original.active === false) {
              return (
                <><i className="fas fa-pause"/> Agendamento inativo.</>
              );
            } else if (
              original.used === false &&
              new Date() >= new Date(original.initialDate) &&
              new Date() <= new Date(original.finishDate)
            ) {
              return (
                <><i className="fas fa-hourglass-half"/> Agendamento pronto para uso.</>
              );
            } else if (
              original.used === false &&
              new Date() > new Date(original.finishDate)
            ) {
              return (
                <><i className="far fa-calendar-times"/> Agendamento perdido.</>
              );
            } else if (original.used === true) {
              return (
                <>
                  <i className="fas fa-check"/> Utilizado dia:{" "}
                  {original.usedDate &&
                    dayMonthYearAtTime(original.usedDate)}{" "}
                </>
              );
            }
            return (
              <><i className="far fa-clock"/>Agendamento ativo.</>
            );
          },
        },

        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.PRE_SCHEDULE_FORM,
          canShowEditButton: ({original}) => !original.used,
          canShowScheduleButton: ({original}) => original.used,
          redirectTo: scheduleEndpoint,
          width: 240,
          minWidth: 240,
          maxWidth: 240,
        })
      ]
    },
  }
}

export default PreScheduleListSchema;
