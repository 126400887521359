import DeviceTypeSelector from "./component/DeviceTypeSelector";
import AccessDirectionSelector, {ACCESS_TYPES} from "./component/AccessDirectionSelector";
import LocalSelector from "../local/component/LocalSelector";
import AccessMethodSelector, {ACCESS_METHODS} from "./component/AccessMethodSelector";

const DeviceFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/devices/'
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Dispositivo' : 'Editar Dispositivo';
    },

    formatObjectToSave(formData) {
      formData.deviceType = formData.deviceType?.id;
      formData.accessDirection = formData.accessDirection?.id
      formData.accessMethod = formData.accessMethod?.id
      return formData;
    },

    formatObjectToGet(formData) {
      formData.deviceType = formData.deviceTypeObject;
      formData.accessDirection = ACCESS_TYPES.find(x => x.id === formData.accessDirection);
      formData.accessMethod = ACCESS_METHODS.find(x => x.id === formData.accessMethod);
      return formData;
    },

    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },

    getSchema() {
      return {
        title: "Dispositivo",
        type: "object",
        required: ["name", "ip", "accessMethod","local","deviceType"],
        properties: {
          name: {type: "string", title: "Nome"},
          ip: {type: "string", title: "IP"},
          deviceType: {
            title: "Tipo de dispositivo",
            type: 'object',
            properties: {
              id: {type: "string", title: "Tipo de dispositivo"},
              name: {type: "string", title: "Tipo de dispositivo"}
            }
          },
          accessMethod:{
            title: "Tipo de dispositivo",
            type: 'object',
            properties: {
              id: {type: "string", title: "Tipo de acesso"},
              name: {type: "string", title: "Tipo de acesso"}
            }
          },
          local:{
            title: "Local de acesso",
            type: 'object',
            properties: {
              id: {type: "string", title: "Tipo de dispositivo"},
              name: {type: "string", title: "Tipo de dispositivo"}
            }
          },
          accessDirection: {
            title: "Direção do acesso",
            type: 'object',
            properties: {
              id: {type: "string", title: "Tipo de dispositivo"},
              name: {type: "string", title: "Tipo de dispositivo"}
            }
          },
          active: {
            type: "boolean",
            title: "Ativo"
          },
          username: {type: "string", title: "Usuário do dispositivo"},
          password: {type: "string", title: "Senha do dispositivo"},
        }
      };
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        },
        "ip": {
          "ui:help": "Ip do device da controladora. Ex.: https://10.120.3.1",
        },
        "deviceType": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<DeviceTypeSelector disabled={props.disabled}
                                        required={props.required}
                                        title={props.title}
                                        value={props.formData}
                                        showCreateButton={false}
                                        setValue={props.onChange}/>
            );
          }
        },
        local: {
          "ui:field": (props) => {
            return (<LocalSelector
                disabled={props.disabled}
                required={props.required}
                title={props.schema.title}
                value={props.formData}
                setValue={props.onChange}/>
            );
          }
        },
        accessDirection: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<AccessDirectionSelector disabled={props.disabled}
                                        required={props.required}
                                        title={props.title}
                                        value={props.formData}
                                        showCreateButton={false}
                                        setValue={props.onChange}/>
            );
          }
        },
        accessMethod: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<AccessMethodSelector disabled={props.disabled}
                                             required={props.required}
                                             title={props.title}
                                             value={props.formData}
                                             showCreateButton={false}
                                             setValue={props.onChange}/>
            );
          }
        }
      }
    }
  }
}

export default DeviceFormSchema;

