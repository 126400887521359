import RoleSelector from "../role/component/RoleSelector";

const ProfileFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Perfil de Acesso' : 'Editar Perfil de Acesso';
    },

    getUrlFindById(id) {
      return `gtt-auth/v1/profiles/${id}`;
    },

    getSaveUrl(id) {
      return `gtt-auth/v1/profiles/${id || ''}`;
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        },
        "authorities": {
          "ui:help": "Permissões que este perfil de acesso possui",
          "ui:field": (props) => {
            return (
              <RoleSelector disabled={props.disabled}
                            isMulti={true}
                            required={true}
                            title={props.schema.title}
                            value={props.formData}
                            setValue={props.onChange}/>
            );
          }
        }
      }
    },

    getSchema() {
      return {
        title: "Perfil de Acesso",
        type: "object",
        required: ["name"],
        properties: {
          name: {type: "string", title: "Nome do perfil"},
          authorities: {
            type: 'array',
            "items": {
              type: 'object',
              properties: {
                id: {type: "string", title: "Permissões"},
              }
            }
          },
        },
      };
    }
  }
}

export default ProfileFormSchema;

