import Stomp from 'stompjs'
import SockJS from 'sockjs-client'

export default class WebsocketService {
  static instance;

  constructor() {
    this.socket = new SockJS('/api/gtt-access-control/websocket', null);
    this.client = Stomp.over(this.socket);
    this.client.debug = null

    this.socket.onopen = function () {
      console.log('open socket');
    };

    this.socket.onclose = function () {
      console.log('socket closed');
    };

    this.socket.onmessage = function (e) {
      console.log('message', e.data);
    };
  }

  static getInstance() {
    if (!WebsocketService.instance) {
      WebsocketService.instance = new WebsocketService();
    }
    return WebsocketService.instance;
  }

  async subscribe(topic, onMessage) {
    await this.connectInWebSocket();

    const eachMessage = (frame) => {
      const object = JSON.parse(frame.body);
      onMessage && onMessage(object);
    }

    const subscription = this.client.subscribe(topic, eachMessage);
    return subscription;
  }

  async connectInWebSocket() {
    return new Promise((resolve, reject) => {
      if (this.client.connected) {
        resolve();
      }
      console.log('try connect in ws');
      this.client.connect({}, (frame) => {
        resolve();
        console.log('Connected: ' + frame);
      });
    });
  }
}
