import PersonSelector from '../person/component/PersonSelector';
import VehicleSelector from '../vehicle/component/VehicleSelector';
import SupplierSelector from '../supplier/component/SupplierSelector';

const ScheduleFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/schedules/';

  const dateNow = new Date();

  const startDate = (date,seconds,ms) => {
    dateNow.setSeconds(seconds,ms);
    return dateNow
  }
  const finishDate = (date,seconds,ms,hours,) => {
    dateNow.setHours(dateNow.getHours() + hours);
    dateNow.setSeconds(seconds,ms);
    return dateNow;
  }

  return {
    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },

    getSchema() {
      return {
        title: 'Agendamentos',
        type: 'object',
        required: [
          'person',
          'company',
          'vehicle',
          'initialDate',
          'finishDate',
        ],
        properties: {
          company: {
            type: 'object',
            title: 'Empresa',
            properties: {
              id: { type: 'string' },
              name: { type: 'string', title: 'Nome' },
            },
          },
          person: {
            type: 'object',
            title: 'Motorista',
            properties: {
              id: { type: 'string' },
              name: { type: 'string', title: 'Nome' },
            },
          },
          assistants: {
            type: 'array',
            title: 'Ajudantes',
            items: {
              type: 'object',
              properties: {
                id: {type: "string", title: "Ajudantes"},
              }
            }
          },
          vehicle: {
            type: 'object',
            title: 'Veículo',
            properties: {
              id: { type: 'string' },
              name: { type: 'string', title: 'Nome' },
            },
          },
          container: {
            type: 'string',
            title: 'Carga / Contêiner / Observações',
          },
          type: {
            type: 'boolean',
            title: 'Entrega ou retirada?',
            enumNames: ['Entrega', 'Retirada'],
          },
          initialDate: {
            type: 'string',
            default: startDate(dateNow, 0,0).toISOString(),
            title: 'Data e horário inicial de chegada',
            format: 'date-time',
          },
          finishDate: {
            type: 'string',
            default: finishDate(dateNow, 0,0,1).toISOString(),
            title: 'Data e horário limite de chegada',
            format: 'date-time',
          },
          active: {
            type: "boolean",
            default: true,
            title: "Agendamento ativo"
          },
          used: {
            type: 'boolean',
            default: false,
            title: 'Agendamento já utilizado?',
            enumNames: ['Sim', 'Não'],
            readOnly : true
          },
        },
      };
    },

    getUiSchema: () => {
      return {
        assistants: {
          "ui:field": (props) => {
            return (
              <PersonSelector disabled={props.disabled}
                            isMulti={true}
                            required={false}
                            title={props.schema.title}
                            value={props.formData}
                            setValue={props.onChange}/>
            );
          }
        },
        person: {
          'ui:field': (props) => {
            return (
              <PersonSelector
                disabled={props.disabled}
                required={props.required}
                title={props.schema.title}
                value={props.formData}
                setValue={props.onChange}
              />
            );
          },
        },
        vehicle: {
          'ui:field': (props) => {
            return (
              <VehicleSelector
                disabled={props.disabled}
                required={props.required}
                title={props.schema.title}
                value={props.formData}
                setValue={props.onChange}
              />
            );
          },
        },
        company: {
          'ui:field': (props) => {
            return (
              <SupplierSelector
                disabled={props.disabled}
                required={props.required}
                title={props.schema.title}
                value={props.formData}
                setValue={props.onChange}
              />
            );
          },
        },
        type: {
          'ui:widget': 'select',
        },
        active: {
          "ui:widget": "checkbox"
        },
      };
    },
  };
};

export default ScheduleFormSchema;
