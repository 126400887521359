const DUPLICATE_DATA_KEY = 'duplicate-data';

export const saveFormData = (entityName, data) => {
  saveData(entityName + '-form-data', data);
}

export const saveDuplicateData = (data) => {
  saveData(DUPLICATE_DATA_KEY, data);
}

export const saveData = (entityName, data) => {
  if (Object.keys(data).filter(x => data[x] && Object.keys(data[x]).length > 0).length === 0) {
    return;
  }
  const json = JSON.stringify(data);
  window.localStorage[entityName] = json;
}

export const getDuplicateData = (defaultValue) => {
  let value = window.localStorage[DUPLICATE_DATA_KEY];
  if (value) {
    delete window.localStorage[DUPLICATE_DATA_KEY];
    return JSON.parse(value);
  }
  return defaultValue;
}

export const getFormData = (entityName, defaultValue) => {
  let value = window.localStorage[entityName + '-form-data'];
  if (value) {
    return JSON.parse(value);
  }
  return defaultValue;
}
