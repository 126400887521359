import {SmallBox} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import {useEffect, useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";

const ScheduleCount = () => {

  const [total, setTotal] = useState('-');

  const loadTotal = async () => {
    try {
      const result = await HttpRequest.get('gtt-access-control/v1/schedules/count');
      setTotal(result.total);
    } catch (e) {
      Notify.error('Falha ao contar agendamentos', e);
    }
  }

  useEffect(() => {
    loadTotal();
  }, [])

  return <SmallBox
    type="secondary"
    icon="fa fa-user"
    count={total} title="Total de agendamentos ativos"
    titlebox={"Agendamentos"}
    navigateTo={ROUTE_PATH.SCHEDULE_LIST}/>

}

export default ScheduleCount;
