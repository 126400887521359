import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {TableButton} from "./TableButton";

const ExitAuthorizationButton = ({
  entity = "",
  endpointToAuthorize = "",
  tableProps = null,
  row = null,
  icon,
  ...props
}) => {

  const exitAuthorizeEntity = async (id, tableProps) => {
    try {

        await HttpRequest.put(`${endpointToAuthorize}/${id}/exit-authorize`)
        Notify.success(`${entity} Saída autorizada!`)

      tableProps.setGlobalFilter(new Date());
    } catch (e) {
      console.log(e);
      Notify.error(`Falha ao autorizar a saída ${entity}: `, e);
    }
  };

  return (
    <TableButton variant={'success'}
                 text={'Autorizar saída'}
                 title={'Autorizar saída'}
                 onClick={() => exitAuthorizeEntity(row.original.id, tableProps)}
                 icon={icon}/>
  )

}

export default ExitAuthorizationButton;
