import {BaseSelectorReadonlyToInputV2} from "../../../components";
export const USER_TYPES = [
    {id: "true", name: "Entrega"},
    {id: "false", name: "Retirada"},
  ];
const ScheduleTypeSelector = ({
  required = false,
  defaultValue,
  title = "Tipo de Agendamento",
  setValue = USER_TYPES,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
  title={title}
  required={required}
  label="name"
  setValue={setValue}
  showCreateButton={false}
  items={USER_TYPES}
  {...props}
  />

}

export default ScheduleTypeSelector;
