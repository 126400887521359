import {InputMaskedRegistrationNumber} from "../../components/form/InputMaskedRegistrationNumber";
import {isValidCPF} from "../../shared/utils/isValidCPF";
import RegistrationTypeSelector2, {REGISTRATION_DOCUMENTS} from "./component/RegistrationTypeSelector2";

const PersonExistsFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/persons/person-exists/'
  return {

    getTitle(isNew) {
      return isNew ? 'Verificação de cadastro' : '';
    },

    submitButtonTitle: 'Verificar',

    formatObjectToSave(formData) {
      formData.arquivos = formData?.arquivos?.filter(x => !!x)
      formData.identificationDocument = {
        ...formData.registrationType,
        value: formData.registrationNumber,
      };
      return formData;
    },

    formatObjectToGet(formData) {
      formData.registrationType = {
        id: formData.identificationDocument.id,
      };
      formData.registrationNumber = formData.identificationDocument.value;
      return formData;
    },

    personDontExists(history) {
      history.replace(`/list/persons/form?wizzard=true`)
    },

    actionPostSave(history, entity) {
      history.replace(`/list/persons/form/${entity?.id}?wizzard=true&exists=true`)
    },

    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },

    validate(formData, errors) {
      if (formData.registrationType.id === "CPF") {
        !isValidCPF(formData?.registrationNumber) && errors?.registrationNumber?.addError("O CPF digitado é inválido")
      }
      return errors
    },

    getUiSchema: () => {
      return {
        "cpf": {
          "ui:help": "CPF 000.000.000-00",
          "ui:field": (props) => {
            return (<InputMaskedRegistrationNumber title={props.schema.title} onChangeText={props.onChange}
                                                   value={props.formData}/>
            );
          }
        },
        "registrationNumber": {
          "ui:help": "Número do Documento.",
          "ui:field": (props) => {
            return (<InputMaskedRegistrationNumber title={props.schema.title} mask={props.schema.mask}
                                                   onChangeText={props.onChange} value={props.formData}
                                                   required={true}/>
            );
          }
        },
        "registrationType": {
          "ui:help": "Selecione acima um documento para identificação.",
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<RegistrationTypeSelector2 disabled={props.disabled} required={props.required}
                                               value={props.formData} setValue={props.onChange}/>
            );
          }
        }
      }
    },

    getSchema() {
      return {
        title: "Pessoas",
        type: "object",
        required: ["birthDate"],
        properties: {
          birthDate: {
            type: "string",
            title: "Data de Nascimento",
            format: "date"
          },
          registrationType: {
            type: "object",
            title: "Tipo de Documento",
            properties: {
              id: {type: 'string'},
              label: {type: 'string'}
            }
          }
        },
        "dependencies": {
          "registrationType": {
            "oneOf": [
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_DOCUMENTS[0].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "0",
                    title: 'CPF'
                  }
                },
              },
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_DOCUMENTS[1].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "2",
                    title: "RG"
                  }
                },
              },
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_DOCUMENTS[2].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "2",
                    title: "PASSAPORTE"
                  }
                },
              },
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_DOCUMENTS[3].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "2",
                    title: "RNE"
                  }
                },
              },
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_DOCUMENTS[4].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "2",
                    title: "OUTROS"
                  }
                },
              },
            ]
          }
        }
      }
    }
  }
}

export default PersonExistsFormSchema;
