import {ButtonGroup, Col, Row} from "react-bootstrap";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {TableButton} from "../button/TableButton";
import * as PropTypes from "prop-types";
import {BlockButton} from "../button/BlockButton";
import {saveDuplicateData} from "../../modules/page-form/PageFormService";
import {formatByIdAndMatch, ROUTE_PATH} from "../../shared/routes/RoutesPath";
import Roles from "../../shared/routes/Roles";
import {ActionButton} from "../button/ActionButton";
import EntityBlockButton from "../button/EntityBlockButton";
import ExitAuthorizationButton from "../button/ExitAuthorizationButton";

BlockButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string
};
const OptionColumn = ({
  endpointToDelete,
  editTo,
  children,
  showAddPhoto = false,
  showDelete = true,
  showBlock = false,
  showVisualize = false,
  showProfile = false,
  entity = "",
  showDuplicateButton = false,
  showEvents,
  showSlide,
  minWidth,
  width,
  maxWidth,
  resetDevice,
  activeAccessor,
  getActiveStatus,
  canShowApproveButton,
  canShowExitAuthorizationButton,
  endpointToAuthorize,
  canShowEditButton,
  canShowScheduleButton,
  redirectTo,

}) => {

  const removeEntity = async (id, tableProps) => {
    try {
      await HttpRequest.delete(`${endpointToDelete}/${id}`)
      Notify.success("Removido!")
      tableProps.setGlobalFilter(new Date());
    } catch (e) {
      Notify.error('Falha ao remover linha', e);
    }
  };

  const col = {
    Header: "Opções",
    sortable: false,
    export: false,
    collapse: true,
    filter: false,

    Cell: (props) => {
      const {row} = props;
      const isActiveByBlock = getActiveStatus ? getActiveStatus(row) : null;
      const showApproveButton  = canShowApproveButton ? canShowApproveButton(row) : false;
      const showExitAuthorizationButton  = canShowExitAuthorizationButton ? canShowExitAuthorizationButton(row) : false;
      const showEdit  = canShowEditButton ? canShowEditButton(row) : true;
      const showCreatedSchedule  = canShowScheduleButton ? canShowScheduleButton(row) : false;
      return (<Row>
          <Col className="text-center">
            <ButtonGroup size="sm">
              {children && children(props)}
              {showExitAuthorizationButton && <ExitAuthorizationButton icon="fas fa-ban" entity={entity}
                                                                       tableProps={props} endpointToAuthorize={endpointToAuthorize} row={row}/>}
              {showEdit && <TableButton icon="fas fa-pencil-alt" to={editTo + row.original.id + "?edit=true"} title="Editar"/>}
              {showCreatedSchedule && <TableButton variant="success" icon="fas fa-calendar-alt" to={redirectTo + row.original.scgEventId} title="Acessar Agendamento"/>}
              {showEvents && <TableButton icon="fas fa-list" to={formatByIdAndMatch({id: row.original.id}, ROUTE_PATH.DEVICE_EVENTS_LIST)} title="Eventos"/>}
              {showVisualize && <TableButton href={row.original?.arquivo?.publicUrl} target={"_blank"}>Visualizar</TableButton>}
              {showDuplicateButton && <TableButton to={editTo} title="Duplicar" classNameButton="mt-1" onClick={() => saveDuplicateData(row?.original)}/>}
              {showAddPhoto && <TableButton icon="fas fa-id-badge" variant="secondary" title="Foto" to={ROUTE_PATH.PERSON_PHOTO_FORM + row.original.id}/>}
              {showApproveButton && <ActionButton icon= "fas fa-check" roles={[Roles.GUARITA]}
                                            to={ROUTE_PATH.APPROVE_PERSON_FORM + row.original.id}
                                            title="Aprovar"/>}

              {showBlock && <EntityBlockButton icon="fas fa-ban" entity={entity}
                                               isActiveByBlock={isActiveByBlock}
                                               tableProps={props} endpointToBlock={endpointToDelete} activeAccessor={activeAccessor} row={row}/>}

              {showDelete &&
              <TableButton icon="fas fa-trash" title="Remover" text='Remover está linha' variant="danger" onClick={() => removeEntity(row.original.id, props)}/>}
              {resetDevice && resetDevice(props)}

            </ButtonGroup>
          </Col>
        </Row>
      )
    }
  }

  if (minWidth) {
    col.minWidth = minWidth;
  }
  if (width) {
    col.width = width;
  }
  if (maxWidth) {
    col.maxWidth = maxWidth;
  }
  return col;
}

export default OptionColumn;
