import {BaseSelectorReadonlyToInputV2} from "../../../components";

const DeviceTypeSelector = ({
  required = false,
  defaultValue,
  title = "Tipo do Dispositivo",
  setValue = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `gtt-access-control/v1/devices/devices-types?sort=nome,ASC&nome=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="name"
    getUrl={getUrl}
    setValue={setValue}
    showCreateButton={false}
    {...props}
  />

}

export default DeviceTypeSelector;
