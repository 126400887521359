import React, {useEffect, useState} from 'react'
import {Card, Row} from "react-bootstrap";

import {Page} from '../../components/page/Page'
import CustomTable from '../page-list/component/CustomTable';
import AccessEventsListSchema from './AccessEventsListSchema';
import {DefaultListSchema} from '../page-list/EntrityListProvider';
import WebsocketService from '../../shared/common/websokcet/WebsocketService';
import HttpRequest from "../../shared/common/http/HttpRequest";
import AccessEventsComponent from "./AccessEventsComponent";

function AccessEventsPage() {

  const lastEventByDevice = {};

  const [devices, setDevices] = useState(null);
  const [lastEvents, setLastEvents] = useState(lastEventByDevice);

  let subscription = null;

  const getDevices = async () => {
    const devicesAtivos = await HttpRequest.get('gtt-access-control/v1/devices?active=true&size=100')
    setDevices(devicesAtivos.content);
  }

  useEffect(() => {
    getDevices();
    WebsocketService.getInstance().subscribe('/topic/access-event/notification', (message) => {
      const accessEvent = JSON.parse(message.payloadInJSON);
      console.log('accessEvent', accessEvent);
      const deviceID = accessEvent.device.id;
      lastEventByDevice[deviceID] = accessEvent;
      console.log('lastEventByDevice', lastEventByDevice);
      setLastEvents({...lastEventByDevice});
    })

    return () => {
      subscription && subscription.unsubscribe();
    }
  }, [])

  console.log('lastEventByDevice', lastEventByDevice);

  const accessEventsListSchema = {...DefaultListSchema, ...AccessEventsListSchema()}
  /**/

  return (
    <Page title="Eventos de acesso">
      <Card className="mb-5">
        <Card.Header className='bg-dark'>Últimos acessos</Card.Header>
        <Card.Body>
          <Row className="" style={{padding: 5}}>
            {devices && devices.map((x, i) => {
                return <AccessEventsComponent key={i} device={x} lastAccessEvent={lastEvents[x.id]}/>
              }
            )}
          </Row>
        </Card.Body>
      </Card>
      <CustomTable entitySchema={accessEventsListSchema}/>
    </Page>
  )
}

export default AccessEventsPage
