import PersonSelector from "../person/component/PersonSelector";
import VehicleSelector from "../vehicle/component/VehicleSelector";
import AccessProfileSelector from "../access-profile/component/AccessProfileSelector";

const ServiceProviderScheduleFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/service-provider-schedules/';
  return {
    title: 'Agendamento de prestador de serviços',

    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },

    getSchema() {
      return {
        title: 'Agendamento de prestador de serviço',
        type: 'object',
        required: ['person', 'initialDate', 'finishDate'],
        properties: {
          person: {
            type: 'object',
            title: 'Pessoa',
            properties: {
              id: {type: 'string'},
              name: {type: 'string', title: 'Nome'},
            },
          },
          assistants: {
            type: 'array',
            title: 'Ajudantes',
            items: {
              type: 'object',
              properties: {
                id: {type: "string", title: "Ajudantes"},
              }
            }
          },
          vehicle: {
            type: 'object',
            title: 'Veículo',
            properties: {
              id: {type: 'string'},
              name: {type: 'string', title: 'Nome'},
            },
          },
          initialDate: {
            type: 'string',
            title: 'Data e horário inicial de chegada',
            format: 'date-time',
          },
          finishDate: {
            type: 'string',
            title: 'Data e horário de saida',
            format: 'date-time',
          },
          serviceProvider: {
            type: 'boolean',
            default: true,
          }
        },
      };
    },
    getUiSchema: () => {
      return {
        assistants: {
          "ui:help": "Caso esteja acompanhado de ajudante.",
          "ui:field": (props) => {
            return (
              <PersonSelector disabled={props.disabled}
                              isMulti={true}
                              required={false}
                              title={props.schema.title}
                              value={props.formData}
                              setValue={props.onChange}/>
            );
          }
        },
        person: {
          'ui:field': (props) => {
            return (
              <PersonSelector
                disabled={props.disabled}
                required={props.required}
                title={props.schema.title}
                value={props.formData}
                setValue={props.onChange}
              />
            );
          },
        },
        vehicle: {
          'ui:field': (props) => {
            return (
              <VehicleSelector
                disabled={props.disabled}
                required={props.required}
                title={props.schema.title}
                value={props.formData}
                label={({frontPlate}) => `Placa: ${frontPlate}`}
                setValue={props.onChange}
              />
            );
          },
        },
        serviceProvider: {
          'ui:widget': 'hidden'
        }
      }
    }
  }
}

export default ServiceProviderScheduleFormSchema;