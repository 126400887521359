import {Page} from "../../components/page/Page";
import React from "react";
import {Col, Row} from "react-bootstrap";
import LastAccessListSchema from "../access/LastAccessListSchema";
import {DefaultListSchema} from "../page-list/EntrityListProvider";
import CustomTable from "../page-list/component/CustomTable";

const listSchema = {...DefaultListSchema, ...LastAccessListSchema()};

const SupplierEventListPage = () => {

  return (
    <Page title="Eventos de Acesso">
      <Row className="mt-5">
        <Col>
          <CustomTable entitySchema={listSchema}/>
        </Col>
      </Row>
    </Page>
  );

}

export default SupplierEventListPage;
