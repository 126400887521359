import {Card, Col, Row} from "react-bootstrap";
import {dayMonthYearAtTime} from "../../../shared/utils/dates";

const ScheduleDetails = ({row}) => {

  if (!row) {
    return <></>
  }

  return (
    <Card>
      <Card.Body>
        <Card className="mb-5">
          <Card.Header style={{fontWeight: "375", backgroundColor: "#7181e1", color: "white",}}>
            <Card.Title style={{marginBottom: "0"}}>Detalhes do Agendamento</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card className="mb-5">
              <Card.Header style={{fontWeight: "375", backgroundColor: "#6c757d", color: "white",}}>
                <Card.Title style={{marginBottom: "0"}}>Dados do Agendamento</Card.Title>
              </Card.Header>
              <Card.Body>
                <Col>
                  <Row className="border-bottom mb-3 p-2 bg-light">
                    <Col>
                      Empresa
                    </Col>
                    <Col>
                      Veículo
                    </Col>
                    <Col>
                      Horário
                    </Col>
                    <Col>
                      Agendamento ativo?
                    </Col>
                    <Col>
                      Estado
                    </Col>
                    <Col>
                      Autorização de saída
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{row.company.name}</p>
                    </Col>
                    <Col>
                      <p> {row.vehicle?.frontPlate}</p>
                    </Col>
                    <Col>
                      <p> {dayMonthYearAtTime(row.initialDate)} até {dayMonthYearAtTime(row.finishDate)}</p>
                    </Col>
                    <Col>
                      <p> {row.active ? "Ativo" : "Cancelado"}</p>
                    </Col>
                    <Col>
                      <p> {row.used ? "Utilizado dia:"+ " " + dayMonthYearAtTime(row.usedDate) : "Não utilizado"}</p>
                    </Col>
                    <Col>
                      <p> {row.exitAuthorization ? 'Autorizada por : ' + row.exitAuthorization.name + ' ' + dayMonthYearAtTime(row.exitAuthorization.when) : "Não autorizada"}</p>
                    </Col>
                  </Row>
                </Col>
              </Card.Body>
            </Card>

            <Card className="mb-5">
              <Card.Header style={{fontWeight: "375", backgroundColor: "#6c757d", color: "white",}}>
                <Card.Title style={{marginBottom: "0"}}>Dados do Motorista</Card.Title>
              </Card.Header>
              <Card.Body>
                <Col>
                  <Row className="border-bottom mb-3 p-2 bg-light">
                    <Col>
                      Nome
                    </Col>
                    <Col>
                      Documento
                    </Col>
                    <Col>
                      CNH
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{row.person?.name}</p>
                    </Col>
                    <Col>
                      <p> {row.person?.identificationDocument.id + ' - ' + row.person?.identificationDocument.value}</p>
                    </Col>
                    <Col>
                      <p> {row.person?.driverLicence?.code}</p>
                    </Col>
                  </Row>
                </Col>

              </Card.Body>
            </Card>

            <Card className="mb-5">
              <Card.Header style={{fontWeight: "375", backgroundColor: "#6c757d", color: "white",}}>
                <Card.Title style={{marginBottom: "0"}}>Dados dos Ajudantes</Card.Title>
              </Card.Header>
              <Card.Body>
                <Col>
                  <Row className="border-top mb-3 p-2 bg-light">
                    <Col>
                      Nome
                    </Col>
                    <Col>
                      Documento
                    </Col>
                  </Row>
                  {row.assistants?.map(x =>
                    <Row className="border-top mb-3 p-2">
                      <Col>
                        <p>{x.name}</p>
                      </Col>
                      <Col>
                        <p> {x.identificationDocument.id + ' - ' + x.identificationDocument.value}</p>
                      </Col>

                    </Row>
                  )}

                </Col>

              </Card.Body>
            </Card>

            <Card className="mb-5">
              <Card.Header style={{fontWeight: "375", backgroundColor: "#6c757d", color: "white",}}>
                <Card.Title style={{marginBottom: "0"}}>Dados da Carga</Card.Title>
              </Card.Header>
              <Card.Body>
                <Col>
                  <Row className="border-bottom mb-3 p-2 bg-light">
                    <Col>
                      Carga
                    </Col>
                    <Col>
                      Entrega / Retirada
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{row.container}</p>
                    </Col>
                    <Col>
                      <p> {row.type ? "Entrega" : "Retirada"}</p>
                    </Col>
                  </Row>
                </Col>

              </Card.Body>
            </Card>

            <Row className="m-1 mb-5 border">

            </Row>
          </Card.Body>
        </Card>
      </Card.Body>
    </Card>)
}

export default ScheduleDetails;
