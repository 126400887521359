import {BaseSelectorReadonlyToInputV2} from "../../../components";

const DeviceSelectorAll = ({
  required = false,
  defaultValue,
  title = "Dispositivo",
  setValue = null,
...props
}) => {
  const getUrl = (filterValue) => {
    return `gtt-access-control/v1/devices?active=true&size=100,ASC&name=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    showCreateButton={false}
    title={title}
    required={required}
    label="name"
    getUrl={getUrl}
    setValue={setValue}
    {...props}
  />
}

export default DeviceSelectorAll;
