import React from "react";

const GTTTheme = React.lazy(() => import('./themes/GTT'));

const TYPE_OF_THEME = {
  GTTTheme: 'GTTTheme',
}

export const ThemeSelector = ({...props}) => {

  const getTheme = () => {
    return TYPE_OF_THEME.GTTTheme;
  }

  const CHOSEN_THEME = getTheme();

  return (
    <>
      <React.Suspense fallback={<></>}>
        {(CHOSEN_THEME === TYPE_OF_THEME.GTTTheme) && <GTTTheme {...props}/>}
      </React.Suspense>

    </>
  )
}
