import {NavLink} from "react-router-dom";
import {getAllMenuByUser} from "../../shared/routes/Menus";
import {Nav} from "react-bootstrap";
import {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {ROUTE_PARAM} from "../../shared/routes/RoutesPath";

export const SidebarItem = () => {

  const menus = getAllMenuByUser();
  const [userRoles, setRoles] = useState(null);
  const currentUser = useSelector(({authState}) => authState.currentUser);

  const formatUrl = (route) => {
    return route.replace(ROUTE_PARAM.COMPANY_ID, currentUser.company.id);
  }

  const canPrint = useCallback((roles) => {
    for (let userRole of userRoles) {
      for (let pathRole of roles) {
        if (userRole === pathRole) {
          return true;
        }
      }
    }
    return false;
  }, [userRoles]);

  const menuItem = ({name, icon, path, children, newTab, roles}, index) => {
    if (roles.length > 0) {
      let hasPermission = canPrint(roles);
      if (!hasPermission) {
        return;
      }
    }
    const hasChildren = children.length > 0 ? <ul className="nav nav-treeview">{children.map(menuItem)}</ul> : null;
    const urlToGo = formatUrl(path);
    return (
      <Nav.Item className="nav-item" id={`${index}`} key={index}>
        <NavLink id={`ni_${name}`} onClick={(e) => {
          if (newTab) {
            window.open(urlToGo, "_blank");
            return;
          }
          if (hasChildren) {
            e.preventDefault();
            setTimeout(() =>{
              let elementOffset = document.getElementById(`${index}`).offsetTop;
              document.getElementById("side-bar").scrollTop = elementOffset - 1;
            }, 500)
          }
        }} to={urlToGo || ''} className="nav-link">
          <i className={`nav-icon fa ${icon}`}/>
          <p>{name}</p>
          {hasChildren && <i className="fas fa-angle-left right"/>}
        </NavLink>
        {hasChildren}
      </Nav.Item>
    )
  }

  useState(() => {
    if (currentUser) {
      const {authorities} = currentUser;
      setRoles(authorities.map(x => x.authority));
    }
  }, [currentUser])

  if (!userRoles) {
    return null;
  }

  return menus.map(menuItem);
}
