import HttpRequest from "../common/http/HttpRequest";

export const ACTION_TYPES = {
  GET_ME: 'user/GET_ME',
  USER_LOADED: 'user/USER_LOADED',
  CONFIG_LOADED: 'user/CONFIG_LOADED'
};

const initialState = {
  currentUser: null,
  isLoading: false,
};

export default function AuthReduce(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.USER_LOADED:

      console.log('action.payload', action.payload)

      if (action.payload) {
        const userRoles = action.payload.authorities?.map(x => x.authority);
        action.payload.hasRole = (roles) => {

          if (Array.isArray(roles)) {
            return userRoles.includes(roles);
          }
          return userRoles.find(x => x === roles)
        }
      }
      return {
        ...state,
        isLoading: false,
        currentUser: action.payload
      };
    case ACTION_TYPES.GET_ME:
      return {...state, isLoading: true};
    default:
      return state;
  }
};

//
// // Actions
//

export const getMe = () => async dispatch => {
  dispatch({type: ACTION_TYPES.GET_ME});
  try {
    const currentUser = await HttpRequest.get('gtt-auth/v1/users/me');
    dispatch({type: ACTION_TYPES.USER_LOADED, payload: currentUser});
  } catch (e) {
    window.location = '/accounts'
  }
};



