import {useState} from "react";
import {useSelector} from "react-redux";
import {TableButton} from "./TableButton";

export const ActionButton = ({
  roles = [],
  ...props
}) => {

  const [userRoles, setRoles] = useState([]);
  const currentUser = useSelector(({authState}) => authState.currentUser);

  useState(() => {
    if (currentUser) {
      const {authorities} = currentUser;
      setRoles(authorities.map(x => x.authority));
    }
  }, [currentUser])

  const canShow = () => {
    if (roles.length === 0) {
      return true;
    }

    for (let userRole of userRoles) {
      for (let pathRole of roles) {
        if (userRole === pathRole) {
          return true
        }
      }
    }
    return false;
  }

  if (!canShow()) {
    console.log('Cant show because you not have permission', userRoles, roles)
    return <></>
  }

  return (
    <TableButton {...props} />
  )
}
