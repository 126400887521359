import CurrentUserPage from "../../modules/user/CurrentUserPage";
import {Page404} from "../../components/page/Page404";
import DashboardPage from "../../modules/dashboard/DashboardPage";
import ListPage from "../../modules/page-list/ListPage";
import FormPage from "../../modules/page-form/FormPage";
import {ROUTE_PATH} from "./RoutesPath";
import Roles from "./Roles";
import ScheduleBoard from "../../modules/schedule/ScheduleBoard";
import {EventOpenGate} from "../../modules/event-open-gate/EventOpenGate";
import SupplierEventListPage from "../../modules/supplier/SupplierEventListPage";
import ScgView from "../../modules/scg-view/ScgView";
import AccessEventsPage from "../../modules/access-events/AccessEventsPage";
import PersonProfilePage from "../../modules/person/PersonProfilePage";
import VehicleListPage from "../../modules/vehicle/VehicleListPage";

export const APP_ROUTES = [
  {
    path: ROUTE_PATH.ACCESS_EVENTS,
    component: AccessEventsPage
  },
  {
    path: ROUTE_PATH.OPEN_GATE_PAGE,
    component: EventOpenGate,
    roles: [Roles.ADMIN, Roles.GUARITA]
  },
  {
    path: ROUTE_PATH.ACCESS_EVENT_LIST,
    component: SupplierEventListPage,
    roles: [Roles.FORNECEDOR]
  },
  {
    path: ROUTE_PATH.LIST_PAGE,
    component: ListPage,
    roles: [Roles.ADMIN, Roles.FORNECEDOR, Roles.GUARITA]
  },
  {
    path: ROUTE_PATH.MONITORAMENTO_VIEW,
    component: ListPage,
    roles: [Roles.GUARITA]
  },
  {
    path: ROUTE_PATH.FORM_PAGE,
    component: FormPage,
    roles: [Roles.ADMIN, Roles.FORNECEDOR]
  },
  {
    path: ROUTE_PATH.FORM_ID_PAGE,
    component: FormPage,
    roles: [Roles.ADMIN]
  },
  {
    path: ROUTE_PATH.LIST_CADASTROS_PAGE,
    component: ListPage,
    roles: [Roles.ADMIN]
  },
  {
    path: ROUTE_PATH.FORM_CADASTROS_PAGE,
    component: FormPage,
    roles: [Roles.ADMIN]
  },
  {
    path: ROUTE_PATH.FORM_ID_CADASTROS_PAGE,
    component: FormPage,
    roles: [Roles.ADMIN]
  },
  {
    path: ROUTE_PATH.FORM_PAGE,
    component: FormPage,
    roles: [Roles.ADMIN, Roles.FORNECEDOR]
  },
  {
    path: ROUTE_PATH.FORM_ID_PAGE,
    component: FormPage,
    roles: [Roles.ADMIN, Roles.FORNECEDOR, Roles.GUARITA]
  },
  {
    path: ROUTE_PATH.PERSON_PROFILE_PAGE,
    component: PersonProfilePage
  },
  {
    path: ROUTE_PATH.PERSON_PROFILE_PAGE_SCHEDULE_TAB,
    component: PersonProfilePage
  },
  {
    path: ROUTE_PATH.PERSON_PROFILE_PAGE_BY_DOCUMENT,
    component: PersonProfilePage
  },
  {
    path: ROUTE_PATH.DASHBOARD,
    component: DashboardPage,
    roles: [Roles.ADMIN]
  },
  {
    path: ROUTE_PATH.DASHBOARD_FORNECEDOR,
    component: ScheduleBoard,
    roles: [Roles.FORNECEDOR]
  },
  {
    path: ROUTE_PATH.USER_PROFILE,
    component: CurrentUserPage,
    roles: [Roles.ADMIN, Roles.FORNECEDOR, Roles.GUARITA, Roles.SUPER]
  },
  {
    path: ROUTE_PATH.SCG_VIEW,
    component: ScgView,
    roles: [Roles.GUARITA, Roles.ADMIN, Roles.SUPER]
  },
  {
    path: ROUTE_PATH.VEHICLE_PAGE_LIST,
    component: VehicleListPage,
  },
  {
    path: '*',
    component: Page404
  }
]





