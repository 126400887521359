import PersonFormSchema from "./PersonFormSchema";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const PersonApproveFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/persons/'
  const base = PersonFormSchema();

  return {

    submitButtonTitle: 'Aprovar',

    getTitle() {
      return 'Aprovar pessoa';
    },

    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    redirectOnSave(history, entity, match) {
      const params = new URLSearchParams(history.location.search)
      if (!params.has('wizzard')) {
        history.goBack();
        return;
      }
      history.replace(ROUTE_PATH.INTERNAL_PERSON_FORM + match.params?.id + '?wizzard=true');

    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}/approve`;
    },

    formatObjectToSave(formData) {
      if (!formData.driverLicence?.code && !formData.driverLicence?.expiryIn){
        formData.driverLicence = null;
      }
      return formData;
    },

    formatObjectToGet(formData) {
      formData.registrationType = {
        id: formData.identificationDocument.id,
        label: formData.identificationDocument.id
      };
      formData.registrationNumber = formData.identificationDocument.value;
      return formData;
    },
    validate(formData, errors) {
      return base.validate(formData, errors);
    },

    getUiSchema: () => {
      const baseUI = base.getUiSchema();
      baseUI.approved = {
        "ui:field": (props) => {
          let x = props.formData;
          if (!x) {
            return (
              <div className="text-center">
                <h3 className={"text-danger"}>Documentos não conferidos!</h3>
                <p>Para liberar o acesso faça a conferência dos documentos do usuário, em seguida clique em aprovar.</p>
              </div>
            )
          } else {
            return (
              <div className="text-center">
                <p>Documentos conferidos por: {props.formData.name}.</p>
              </div>
            )
          }
        }
      }
      return baseUI;
    },

    getSchema() {
      const baseSchema = base.getSchema();
      baseSchema.properties.approved = {
        title: "...",
        type: 'object',
        properties: {
          user: {type: "string", title: "Current user"}
        }
      };
      return baseSchema;
    }
  }

}

export default PersonApproveFormSchema;