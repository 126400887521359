import PDFUtils from "./PDFUtils";
import {FormatPdfData} from "./FomatPdfData";
import {pdfFileTypes} from "./pdfFileTypes";

export default class ExportData {

  static exportToPDF(data, columns,title) {

    if (title === pdfFileTypes[0].id) { //Type Person List
      data = FormatPdfData(data);
    }

    const pdf = PDFUtils.convertArrayOfObjectsToPDF({data, columns: columns.filter(x => x.export !== false), title});
    if (pdf == null) {
      return;
    }
    const dataText = 'data:application/pdf;charset=utf-8,' + pdf;
    const url = encodeURI(dataText);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.click();

  }
}