import PersonSelector from "../person/component/PersonSelector";
import VehicleSelector from "../vehicle/component/VehicleSelector";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const InternalPersonFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/internal-persons/'
  return {

    getTitle(isNew) {
      return isNew ? 'Pessoas de trânsito interno' : 'Pessoas de trânsito interno';
    },

    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    formatObjectToGet(data) {
      data = {
        person: data,
        initialDate: data.accessCompany?.internalAccess?.initialDate,
        finishDate: data.accessCompany?.internalAccess?.finishDate,
        vehicles: data.accessCompany?.internalAccess?.vehicles
      }
      return data;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },

    redirectOnSave(history, entity, match, {currentUser}) {
      const params = new URLSearchParams(history.location.search)
      console.log("currentUser", currentUser)
      if (!params.has('wizzard')) {
        history.goBack();
        return;
      }
      history.replace(ROUTE_PATH.PERSON_PROFILE_PAGE_WIZ + match.params?.id);
    },

    getUiSchema: () => {
      return {
        person: {
          'ui:field': (props) => {
            return (
              <PersonSelector
                disabled={props.disabled}
                required={props.required}
                title={props.schema.title}
                value={props.formData}
                setValue={props.onChange}
              />
            );
          },
        },
        vehicles: {
          'ui:field': (props) => {
            return (
              <VehicleSelector
                disabled={props.disabled}
                required={false}
                title={props.schema.title}
                value={props.formData}
                setValue={props.onChange}
                label={({frontPlate}) => `Placa: ${frontPlate}`}
                isMulti={true}
              />
            );
          },
        },
      }
    },

    getSchema() {
      return {
        title: "Pessoas",
        type: "object",
        required: ["person"],
        properties: {
          person: {
            type: 'object',
            title: 'Pessoa',
            properties: {
              id: {type: 'string'},
              name: {type: 'string', title: 'Nome'},
            },
          },
          vehicles: {
            type: 'array',
            title: 'Veículo',
            items: {
              type: 'object',
              properties: {
                id: {type: "string", title: "Veículo"},
              }
            }
          },
          initialDate:{
            type: 'string',
            title: 'Data de inicio do Acesso interno',
            format: 'date-time'
          },
          finishDate: {
            type: 'string',
            title: 'Data de validade final',
            format: 'date-time',
          },
        },
      }
    }
  }
}

export default InternalPersonFormSchema;
