import {BaseSelectorReadonlyToInputV2} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const IntegrationConfig = ({
  required = false,
  defaultValue,
  title = "Sistema Integrado",
  setValue = null,
  showCreateButton = true,
  type,
  useFirst,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `gtt-access-control/v1/integration-configs?name=${filterValue}&type=${type}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="url"
    getUrl={getUrl}
    setValue={setValue}
    showCreateButton={false}
    useFirst={useFirst}
    {...props}
  />

}

export default IntegrationConfig;
