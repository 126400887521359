import {Page} from "../../components/page/Page";
import UserDetailsForm from "./component/UserDetailsForm";
import {useSelector} from "react-redux";
import UserDetailsPasswordForm from "./component/UserDetailsPasswordForm";
import {Col, Row} from "react-bootstrap";

const CurrentUserPage = () => {

  const {currentUser} = useSelector(({authState}) => ({
    currentUser: authState.currentUser,
  }));
  console.log("AQUIIIII", currentUser)

  return (<Page title="Meus dados">
      <Row>
        <Col className="col-sm-12 col-md-6">
          <UserDetailsForm currentUser={currentUser}/>
        </Col>

        <Col className="col-sm-12 col-md-6">
          <UserDetailsPasswordForm/>
        </Col>
      </Row>
    </Page>
  )

};
export default CurrentUserPage;
