import React, {useEffect, useState} from "react";
import PropertyAndValueText from "../../../components/text/PropertyAndValueText";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import {useRouteMatch} from "react-router-dom";
import {anyMath} from "../../../components/route/Routes";
import Roles from "../../../shared/routes/Roles";
import {useSelector} from "react-redux";
import {dayMonthYearAtTime} from "../../../shared/utils/dates";

function PersonProfileComponent() {

  const [roles, setRoles] = useState(null);
  const currentUser = useSelector(({authState}) => authState.currentUser);
  const [person, setPerson] = useState(null);
  const match = useRouteMatch();

  async function personData() {
    let data;
    if(match.params.hasOwnProperty("id")){
      data = await HttpRequest.get(`gtt-access-control/v1/persons/${match.params?.id}`);
    }
    if(match.params.hasOwnProperty("documentNumber")){
      data = await HttpRequest.get(`gtt-access-control/v1/persons/documents/${match.params?.documentNumber}`);
    }
    setPerson(data);
  }

  useEffect(() => {
    personData();
  }, [match.params.id]);

  useState(() => {
    if (currentUser) {
      const {authorities} = currentUser;
      setRoles(authorities.map((x) => x.authority));
    }
  }, [currentUser])

  if (!roles) {
    return null;
  }

  if (!person) {
    return <h2> Carregando.. </h2>
  }

  if (anyMath(roles, Roles.FORNECEDOR) && !currentUser.super) {
    return (
      <>
        <PropertyAndValueText property="Empresa(s):" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={person.accessCompany?.company.name}/>
        <PropertyAndValueText property="Acesso interno:" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={person.accessCompany?.canDoInternalAccess ?
          <> <i className="fas fa-check"> </i> Permitido</> :
          <><i className="fas fa-times"> </i> Negado</>}/>
        <PropertyAndValueText property="Inicio do acesso Interno:" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={dayMonthYearAtTime(person.accessCompany?.internalAccess?.initialDate)}/>
        <PropertyAndValueText property="Validade do Acesso Interno:" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={dayMonthYearAtTime(person.accessCompany?.internalAccess?.finishDate)}/>
        <PropertyAndValueText property="Veículo(s):" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={person.accessCompany?.internalAccess?.vehicles ?
          person.accessCompany?.internalAccess?.vehicles.map((x, frontPlate) => <li key={frontPlate}>{x.frontPlate}</li>)
          : "Nenhum veículo cadastrado"}/>
      </>
    )
  } else {
    return (
      <>
        <PropertyAndValueText property="Empresa(s):" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={person.accessCompanies ?
          person.accessCompanies.filter(c => c.deleted === false).map((x, companies) => <li key={companies}>{x.company?.name}</li>)
          : person?.company?.name}/>
        <PropertyAndValueText property="Acesso interno:" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={person.accessCompanies?.find(x => x.canDoInternalAccess)  ?
          <> <i className="fas fa-check"> </i> Permitido</> :
          <><i className="fas fa-times"> </i>Negado</>}/>
        <PropertyAndValueText property="Inicio do Acesso Interno:" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={dayMonthYearAtTime(person.accessCompanies[0]?.internalAccess?.initialDate)}/>
        <PropertyAndValueText property="Validade do Acesso Interno:" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={dayMonthYearAtTime(person.accessCompanies[0]?.internalAccess?.finishDate)}/>
        <PropertyAndValueText property="Veículo(s):" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={person.accessCompanies[0]?.internalAccess?.vehicles ?
          person.accessCompanies?.map(x =>  x.internalAccess?.vehicles?.map((x, frontPlates) => <li key={frontPlates}>{x.frontPlate}</li>))
          : "Nenhum veículo cadastrado"}/>
      </>
    )
  }
}

export default PersonProfileComponent;