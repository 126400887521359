import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";

export const REGISTRATION_DOCUMENTS = [
  {id: 'CPF', label: 'CPF'},
  {id: 'RG', label: 'RG'},
  {id: 'PASSAPORTE', label: 'PASSAPORTE'},
  {id: 'RNE', label: 'RNE'},
  {id: 'OUTROS', label: 'OUTROS'}

];

const RegistrationTypeSelector2 = ({
    required = false,
    title = "Tipo de Documento de identificação",
    setValue = null,
    ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    title={title}
    required={required}
    label="label"
    setValue={setValue}
    items={REGISTRATION_DOCUMENTS}
    {...props}
  />
}

export default RegistrationTypeSelector2;
