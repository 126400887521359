import {dayMonthYearAtTime} from "../../../shared/utils/dates";
import StatusSelector from "../../internal-schedule/component/StatusSelector";
import {SelectColumnFilter} from "../../../components/table/TableFilter";
import OptionColumn from "../../../components/table/OptionColumn";
import VehicleSelector from "../../schedule/component/VehicleSelector";

const InternalPersonTab = (personId, canShowPersonData = true) => {

  const endpoint = 'gtt-access-control/v1/internal-persons';

  return {

    getEndpoint: () => `${endpoint}?personId=${personId}&`,

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "person.id",
          Cell: ({row}) => row.original?.name ? (row.original.name) : "Não identificado"
        },
        {
          Header: "Documento",
          accessor: "identificationDocument",
          Cell: ({row: {original}}) => {
            return original.identificationDocument ? original.identificationDocument.id + ' - ' + original.identificationDocument.value : null
          }
        },
        {
          Header: "Empresa",
          accessor: "accessCompany.company.name",
          Filter: false,
          Cell: ({row}) => { return row.original.accessCompany?.company?.name}
        },
        {
          Header: "Veículo(s)",
          accessor: "vehicle",
          Filter: SelectColumnFilter,
          CustomSelector: VehicleSelector,
          Cell: ({row}) => {
            return row.original.accessCompany?.internalAccess?.vehicles ?
              row.original.accessCompany?.internalAccess?.vehicles.map(x => <li>{x.frontPlate}</li>)
              : "Nenhum veículo cadastrado"
          }
        },
        {
          Header: "Status",
          accessor: "isValid",
          Filter: SelectColumnFilter,
          CustomSelector: StatusSelector,
          Cell: ({row}) => row.original?.accessCompany?.canDoInternalAccess ?
            <> <i className="fas fa-check"> </i> Permitido</> :
            <><i className="fas fa-times"> </i> Negado</>,
        },
        {
          Header: "Data de inicio",
          accessor: "accessCompany.internalAccess.initialDate",
          Filter: false,
          Cell: ({row}) => dayMonthYearAtTime(row.original.accessCompany.internalAccess.initialDate)
        },
        {
          Header: "Validade",
          accessor: "accessCompany.internalAccess.finishDate",
          Filter: false,
          Cell: ({row}) => dayMonthYearAtTime(row.original.accessCompany.internalAccess.finishDate)
        },
        OptionColumn({
          endpointToDelete: endpoint,
          canShowEditButton: () => false,
          showBlock: true,
          getActiveStatus: ({original}) => !original.accessCompany?.internalAccess?.internalBlock,
        })
      ].filter(x => {
        return canShowPersonData === false ? !['Nome', 'Documento'].includes(x.Header) : true;
      })
    },
  }
}

export default InternalPersonTab;

