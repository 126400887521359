import {BaseSelectorReadonlyToInputV2} from "../../../components";

export const USER_TYPES = [
  {id: "true", name: "Ativo"},
  {id: "false", name: "Bloqueado"},
];

const UserStatusSelector = ({
  required = false,
  defaultValue,
  title = "Estado do Usuário",
  setValue = USER_TYPES,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    title={title}
    required={required}
    label="name"
    setValue={setValue}
    showCreateButton={false}
    items={USER_TYPES}
    {...props}
  />

}

export default UserStatusSelector;
