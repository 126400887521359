import UserListSchema from "../user/UserListSchema";
import DeviceListSchema from "../devices/DeviceListSchema";
import SupplierListSchema from "../supplier/SupplierListSchema";
import PersonListSchema from "../person/PersonListSchema";
import VehicleListSchema from "../vehicle/VehicleListSchema";
import ScheduleListSchema from "../schedule/ScheduleListSchema";
import DeviceEventsListSchema from "../device-events/DeviceEventsListSchema";
import ProfileListSchema from "../profile/ProfileListSchema";
import RoleListSchema from "../role/RoleListSchema";
import IntegrationConfigListSchema from "../integration-config/IntegrationConfigListSchema";
import AccessProfileListSchema from "../access-profile/AccessProfileListSchema";
import LocalListSchema from "../local/LocalListSchema";
import InternalPersonListSchema from "../internal-schedule/InternalPersonListSchema";
import ServiceProviderScheduleListSchema from "../service-provider-schedule/ServiceProviderScheduleListSchema";
import InternalVehicleListSchema from "../vehicle/InternalVehicleListSchema";
import PendingPersonListSchema from "../person/PendingPersonListSchema";
import GateListSchema from "../gate/GateListSchema";
import OCREventListSchema from "../ocr-events/OCREventListSchema";
import ExitAuthorizationListSchema from "../schedule/ExitAuthorizationListSchema";
import PreScheduleListSchema from "../pre-schedule/PreScheduleListSchema";

export const LIST_ENTITY_SCHEMAS = {
  'roles': RoleListSchema,
  'device-events': DeviceEventsListSchema,
  'users': UserListSchema,
  'devices': DeviceListSchema,
  'suppliers': SupplierListSchema,
  'persons': PersonListSchema,
  'vehicles': VehicleListSchema,
  'schedules': ScheduleListSchema,
  'integration-configs': IntegrationConfigListSchema,
  'profiles': ProfileListSchema,
  'access-profiles': AccessProfileListSchema,
  'locals':LocalListSchema,
  'internal-person': InternalPersonListSchema,
  'service-provider-schedules': ServiceProviderScheduleListSchema,
  'internal-vehicles': InternalVehicleListSchema,
  'pending-person' : PendingPersonListSchema,
  'gates': GateListSchema,
  'ocr-events': OCREventListSchema,
  'exit-authorization': ExitAuthorizationListSchema,
  'pre-schedules': PreScheduleListSchema,
};

export const DefaultListSchema = {
  defaultSort: {id: 'createdAt', desc: true},
}

export const getEntityListSchema = (entityName) => {
  const result = LIST_ENTITY_SCHEMAS[entityName];
  if (!result) {
    throw new Error('Entity not found: ' + entityName);
  }
  return result;
}
