import {ThemeContext} from "../../thema/ThemeProvider";

export const Footer = () => {

  return (
    <ThemeContext.Consumer>
      {theme => (
        <footer className="main-footer">
          <strong>Copyright © 2021-2021 <a href={theme.companyLink}>{theme.title}</a> - <a href="https://gttlogistics.com.br/">GTT</a>. </strong>
          Todos Direitos Reservados.
          <div className="float-right d-none d-sm-inline-block">
            <b>Versão</b> {process.env.REACT_APP_BUILD_DATE}
          </div>
        </footer>
      )}
    </ThemeContext.Consumer>);
}
