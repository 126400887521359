import {BaseSelectorReadonlyToInputV2} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const SupplierSelector = ({
  required = false,
  defaultValue,
  title = "Empresas",
  setValue = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `gtt-auth/v1/companies?sort=name,ASC&name=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="name"
    getUrl={getUrl}
    setValue={setValue}
    showCreateButton={false}
    pathToCreate={ROUTE_PATH.SUPPLIER_FORM_LIST}
    placeholder={"Empresa que receberá a visita"}
    {...props}
  />

}

export default SupplierSelector;
