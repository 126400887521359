import {SelectColumnFilter} from "../../../components/table/TableFilter";
import StatusSelector from "../../internal-schedule/component/StatusSelector";
import {dayMonthYearAtTime} from "../../../shared/utils/dates";

const InternalAccessVehicle = (vehicleId) => {

  const endpoint = 'gtt-access-control/v1/internal-persons';

  return {
    title: 'Pessoas de Acesso Interno',

    getEndpoint: () => `${endpoint}?vehicle=${vehicleId}&`,

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "name",
        },
        {
          Header: "Documento",
          accessor: "identificationDocument",
          Cell: ({row: {original}}) => {
            return original.identificationDocument ? original.identificationDocument.id + ' - ' + original.identificationDocument.value : null
          }
        },
        {
          Header: "Veículo(s)",
          accessor: "vehicle",
          Filter: false,
          Cell: ({row}) => {
            return row.original.accessCompany?.internalAccess?.vehicles ?
              row.original.accessCompany?.internalAccess?.vehicles.map(x => <li>{x.frontPlate}</li>)
              : "Nenhum veículo cadastrado"
          }
        },
        {
          Header: "Empresa",
          accessor: "accessCompany.company.name",
          Filter: false,
        },
        {
          Header: "Status",
          accessor: "isValid",
          Filter: SelectColumnFilter,
          CustomSelector: StatusSelector,
          Cell: ({row}) => row.original?.accessCompany?.canDoInternalAccess ?
            <> <i className="fas fa-check"> </i> Permitido</> :
            <><i className="fas fa-times"> </i> Negado</>,
        },
        {
          Header: "Validade do Acesso",
          accessor: "accessCompany.internalAccess.finishDate",
          Filter: false,
          Cell: ({row}) => dayMonthYearAtTime(row.original.accessCompany.internalAccess.finishDate)
        },
      ]
    },

  }
}

export default InternalAccessVehicle;

