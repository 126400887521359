import {Page} from "../../components/page/Page";
import {useCallback, useEffect, useState} from "react";
import {DefaultFormSchema, getEntityFormSchema} from "./EntrityFormProvider";
import {Card} from "react-bootstrap";
import {CustomForm} from "../../components";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {Loading} from "../../components/loading/Loading";
import {useLocation} from "react-router-dom";
import {getDuplicateData, getFormData, saveFormData} from "./PageFormService";

const FormPage = ({match}) => {

  const location = useLocation();
  const [entitySchema, setEntitySchema] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(null);
  const [originFormData, setOriginFormData] = useState({});

  const formatObject = (data) => {
    if (entitySchema.formatObjectToGet) {
      data = entitySchema.formatObjectToGet(data);
    }

    for (const property in data) {
      const value = data[property];
      if (value === null || value === undefined) {
        delete data[property];
      }
    }
    return data;
  }

  const findById = useCallback(async (url) => {
    try {
      let data = await HttpRequest.get(url);
      setOriginFormData(data);

      data = formatObject(data);
      saveFormData(match.params?.entity, data);

      setFormData(data);
      setOriginFormData(data);
    } catch (e) {
      if (entitySchema.onNotFoundError) {
        entitySchema.onNotFoundError(e);
      } else {
        Notify.error('Falha ao carregar dados da entidade', e);

      }
    } finally {
      setIsLoading(false);
    }
  }, [entitySchema]);

  useEffect(() => {
    setIsLoading(true);
    const entityName = match.params?.entity;
    if (!entityName) {
      return;
    }
    const entityListSchema = getEntityFormSchema(entityName);
    const schema = {...DefaultFormSchema, ...entityListSchema()};
    setEntitySchema(schema);
  }, [match.params?.entity])

  useEffect(() => {
    if (!entitySchema) {
      return;
    }
    setIsLoading(true);
    const data = new URLSearchParams(location.search);
    if (data.get('useLocalData')) {
      const dataToUse = getFormData(match.params?.entity, {});
      console.log('dataToUse', dataToUse);
      setFormData(dataToUse);
      setIsLoading(false);
      return;
    }

    const entityId = match.params?.id;
    if (entityId && entitySchema.getUrlFindById) {
      findById(entitySchema.getUrlFindById(entityId));
    } else {
      if (entityId === undefined) {
        let duplicateData = getDuplicateData();
        if (duplicateData) {
          delete duplicateData.id;
          delete duplicateData.version;
          duplicateData = formatObject(duplicateData);
          setFormData(duplicateData);
          saveFormData('processos', duplicateData)
        } else {
          setFormData({});
        }
        setIsLoading(false);
      }
    }
  }, [match.params?.id, entitySchema, findById])

  return (<Page title={entitySchema?.getTitle(!match.params.id)}>
    <Card>
      <Card.Body>
        <Loading isLoading={isLoading}/>
        {!isLoading && <CustomForm entityName={match.params?.entity} defaultValue={formData} originFormData={originFormData} entitySchema={entitySchema} disabled={isLoading}/>}
      </Card.Body>
    </Card>
  </Page>)

}

export default FormPage;
