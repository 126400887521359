import React, {useMemo} from "react";
import {DefaultListSchema} from "../../page-list/EntrityListProvider";
import PersonEventList from "./PersonEventList";
import {useHistory, useLocation} from "react-router-dom";
import CustomTable from "../../page-list/component/CustomTable";
import {Button, Col, Row, Tab, Tabs} from "react-bootstrap";
import InternalPersonTab from "./InternalPersonTab";
import ExternalScheduleTab from "./ExternalScheduleTab";

function PersonTabs({person, scgEventId}) {

  const history = useHistory();
  const location = useLocation();

  function redirect() {
    history.push(`/list/internal-person/form/${person.id}`)
  }

  const initialTab = () => {
    if (location.pathname.includes(`scheduleTab`)) {
      return "externalSchedule";
    }
    return "accessEvent" ;
  }

  function redirectToScheduleForm() {
    history.push(`/list/schedules/form`)
  }

  const personEvent = useMemo(() => ({...DefaultListSchema, ...PersonEventList(person.id)}), [person]);
  const personSchedule = useMemo(() => ({...DefaultListSchema, ...ExternalScheduleTab(person.id, scgEventId)}), [person]);
  const personInternalAccess = useMemo(() => ({...DefaultListSchema, ...InternalPersonTab(person.id, false)}), [person]);

  return (

    <Tabs defaultActiveKey={initialTab} id="uncontrolled-tab-example" className="mb-3">
      <Tab eventKey="accessEvent" mountOnEnter={true} title="Eventos de Acesso">
        <h2>Eventos de Acesso</h2>
        <CustomTable entitySchema={personEvent}/>
      </Tab>
      <Tab eventKey="internalAccess" mountOnEnter={true} title="Acesso Interno">
        <Row>
          <Col>
            <h2>Acesso Interno</h2>
          </Col>
          <Col className="text-right">
            <Button className={"mb-3"} onClick={redirect}>Adicionar / Editar Acesso Interno</Button>
          </Col>
        </Row>
        <CustomTable entitySchema={personInternalAccess}/>
      </Tab>
      <Tab eventKey="externalSchedule" mountOnEnter={true} title="Agendamento Externo">
        <Row>
          <Col>
            <h2>Agendamento Externo</h2>
          </Col>
          <Col className="text-right">
            <Button onClick={redirectToScheduleForm}>Criar Novo Agendamento</Button>
          </Col>
        </Row>
        <br/>
        <CustomTable entitySchema={personSchedule}/>
      </Tab>
    </Tabs>

  )

}

export default PersonTabs;
