import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";

export const ACCESS_METHODS = [
  {id: 'PEDESTRIAN', label: 'Acesso de pedestres'},
  {id: 'VEHICLE', label: 'Acesso de veículos'}
];

const AccessDirectionSelector = ({
  required = false,
  title = "Método de acesso",
  setValue = ACCESS_METHODS,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    title={title}
    required={required}
    label="label"
    setValue={setValue}
    items={ACCESS_METHODS}
    {...props}
  />
}

export default AccessDirectionSelector;
