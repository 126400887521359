import React from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 400,
  height: 300,
  facingMode: "user"
};

const WebcamComponent = () => <Webcam/>;

const WebcamCapture = ({onLoadPhoto}) => {
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      onLoadPhoto && onLoadPhoto(imageSrc)
    },
    [webcamRef, onLoadPhoto]
  );

  return (
    <div className="d-flex flex-column align-items-center">
      <Webcam
        audio={false}
        height={540}
        id={"webcamScreen"}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={960}
        videoConstraints={videoConstraints}
      />
      <button className="btn btn-primary" style={{marginTop:"-55px", zIndex:'1'}} onClick={capture}>Tirar Foto</button>
    </div>
  );
};

export default WebcamCapture;
