import {Gravatar} from "./Gravatar";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

export const CurrentUser = () => {

  const {isLoading, currentUser} = useSelector(({authState}) => ({
    isLoading: authState.isLoading,
    currentUser: authState.currentUser
  }));

  if (isLoading || !currentUser) {
    return null;
  }

  return (
    <div>
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          <Gravatar email={currentUser.username} className="img-circle elevation-2" alt="Avatar do Usuário"/>
        </div>
        <div className="info">
          <Link to={ROUTE_PATH.USER_PROFILE} className="d-block text-light">
            {currentUser.name} {currentUser.company.name ? ' - ' + currentUser.company.name : ''}
          </Link>
        </div>
      </div>
    </div>
  )
}
