import {useEffect, useState} from "react";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {Loading} from "../../components/loading/Loading";

const ScgView = () => {

  const [scgHttpConfig, setScgHttpConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const endpoint = 'gtt-access-control/v1/integration-configs?type=SCG';

  useEffect(async () => {
    if (scgHttpConfig) {
      return;
    }
    try {
      setIsLoading(true);
      let data = await HttpRequest.get(endpoint);
      setScgHttpConfig(data.content[0])
    } catch (e) {
      Notify.error("Falha ao carregar dados", e)
    } finally {
      setIsLoading(false)
    }
  }, [scgHttpConfig]);

  return(
    <Loading isLoading={isLoading}>
      <iframe src={scgHttpConfig?.url} className="w-100 vh-100" />
    </Loading>
  );
}

export default ScgView;