import {BaseSelectorReadonlyToInputV2} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const VehicleSelector = ({
  required = false,
  defaultValue,
  title = "Estado",
  setValue = null,
  isMulti,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `gtt-access-control/v1/vehicles?sort=frontPlate,ASC&plate=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label={({frontPlate, backPlate}) => `PF: ${frontPlate} - PT: ${backPlate}`}
    getUrl={getUrl}
    setValue={setValue}
    isMulti={isMulti}
    showCreateButton={true}
    pathToCreate={ROUTE_PATH.VEHICLE_FORM}

    {...props}
  />

}

export default VehicleSelector;
