const VehicleFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/vehicles/'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },

    getSchema() {
      return {
        title: "Veículos",
        type: "object",
        required: ["frontPlate"],
        properties: {
          frontPlate: {type: "string", title: "Placa dianteira"},
          backPlate: {type: "string", title: "Placa traseira"},
          internalVehicle: {type: "boolean", title: "Veículo de frota"}
        }
      }
    },

    getUiSchema: () => {
      return {
        internalVehicle: {
          "ui:help": "Indica se o veículo tem acesso livre, indepente do motorista."
        }
      }
    }
  }
}

export default VehicleFormSchema;
