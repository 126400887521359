import SupplierSelector from "../supplier/component/SupplierSelector";
import HttpMethodoSelector from "./component/HttpMethodoSelector";
import IntegrationTypeSelector from "./component/IntegrationTypeSelector";

const IntegrationConfigFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/integration-configs/'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.type = formData.type?.id;
      formData.httpMethod = formData.httpMethod?.id;
      return formData;
    },

    formatObjectToGet(formData) {
      formData.type = {id: formData.type, name: formData.type};
      formData.httpMethod = {
        id: formData.httpMethod,
        name: formData.httpMethod
      };
      return formData;
    },

    getSchema() {
      return {
        title: "Configuração de integrações",
        type: "object",
        required: ["url", "httpMethod", "type"],
        properties: {
          url: {type: "string", title: "URL"},
          httpMethod: {
            title: "Método HTTP",
            type: 'object',
            properties: {
              id: {type: "string", title: "Tipo de dispositivo"},
            }
          },
          type: {
            title: "Tipo de Integração",
            type: 'object',
            properties: {
              id: {type: "string", title: "Tipo de dispositivo"},
            }
          },
          headers: {
            "additionalProperties": {
              "type": "string"
            },
          },
          // bodyTemplate: { type: "string", title: "Body" },
          // company: {
          //   type: 'object',
          //   title: 'Empresa',
          //   properties: {
          //     id: { type: "string" },
          //     name: { type: "string", title: "Nome" }
          //   }
          // }
        }
      }
    },
    getUiSchema: () => {
      return {
        httpMethod: {
          "ui:field": (props) => {
            return (<HttpMethodoSelector
                    disabled={props.disabled}
                    required={props.required}
                    title={props.schema.title}
                    value={props.formData}
                    setValue={props.onChange}/>
            );
          }
        },
        type: {
          "ui:field": (props) => {
            return (<IntegrationTypeSelector
                    disabled={props.disabled}
                    required={props.required}
                    title={props.schema.title}
                    value={props.formData}
                    setValue={props.onChange}/>
            );
          }
        },
      }
    }
  }
}

export default IntegrationConfigFormSchema;
