import Roles from "./Roles";
import {ROUTE_PATH} from "./RoutesPath";

const Menu = ({
  name, path, icon, isPrivate = true, children = [],
  roles = [], newTab = false,
}) => {

  let anyRoles = roles;
  if (children) {
    anyRoles.push()
    children.forEach(x => {
      if (x.roles) {
        x.roles.forEach(y => anyRoles.push(y));
      }
    })
  }
  return {name, path, icon, isPrivate, children, roles: anyRoles, newTab};
};

export const getAllMenuByUser = () => {
  return AllMenus;
}

export const getMenuByPath = (path) => {
  const menu = _getMenuByPath(path);
  if (menu && menu.path) {
    return menu;
  }
  return _getMenuByPath(path + '/');
}

const _getMenuByPath = (newPath) => {
  const path = newPath;
  for (let menu of AllMenus) {
    if (menu.path === path) {
      return menu;
    }

    for (let menuChildren of menu.children) {
      if (menuChildren.path === path) {
        return menuChildren;
      }
      for (let menuGrandson of menuChildren.children) {
        if (menuGrandson.path === path) {
          return menuGrandson
        }
      }
    }
  }
  if (path === '/') {
    return AllMenus[0];
  }
  return null;
}

export const AllMenus = [

  Menu({
    name: 'Início', path: ROUTE_PATH.HOME,
    icon: 'fa-tachometer-alt',
    roles: [Roles.ADMIN, Roles.FORNECEDOR, Roles.SUPER]
  }),

  Menu({
    name: 'Abrir gate', path: ROUTE_PATH.OPEN_GATE_PAGE,
    icon: 'fa-tachometer-alt',
    roles: [Roles.GUARITA]
  }),

  Menu({
    name: 'Eventos de acesso', path: ROUTE_PATH.ACCESS_EVENTS,
    icon: 'fa-desktop',
    roles: [Roles.GUARITA, Roles.SUPER, Roles.ADMIN]
  }),

  Menu({
    name: 'Acessos de pessoas', path: ROUTE_PATH.ACCESS_EVENT_LIST, icon: 'fa-desktop',
    roles: [Roles.FORNECEDOR]
  }),

  Menu({
    name: 'Autorizações de saída', path: ROUTE_PATH.EXIT_AUTHORIZATION,
    icon: 'fa-desktop',
    roles: [Roles.FORNECEDOR, Roles.SUPER, Roles.ADMIN]
  }),

  Menu({name: 'Cadastros a aprovar', path:ROUTE_PATH.PERSON_PENDING_LIST, icon: 'fa-address-card',
    roles:[Roles.ADMIN, Roles.SUPER,Roles.GUARITA]}),

  Menu({
    name: 'SCG', path: ROUTE_PATH.SCG_VIEW, icon: 'fa-desktop',
    roles: [Roles.GUARITA, Roles.SUPER, Roles.ADMIN]
  }),
  Menu({
    name: 'Cadastros', path: '/cadastros',
    icon: 'fa-cogs', children: [
      Menu({name: 'Pessoas - Cadastrar', path: ROUTE_PATH.PERSON_LIST, icon: 'fa-user-friends'}),
      Menu({name: 'Veículos', path: ROUTE_PATH.VEHICLE_LIST, icon: 'fa-car'}),
      Menu({name: 'Veículos de frota', path: ROUTE_PATH.INTERNAL_VEHICLE_LIST, icon: 'fa-car'}),
    ], roles: [Roles.ADMIN, Roles.FORNECEDOR]
  }),

  Menu({
    name: 'Agendamentos / Acesso', path: '/agendamentos',
    icon: 'fa-calendar-alt', children: [
      Menu({name: 'Pré agendamento', path: ROUTE_PATH.PRE_SCHEDULE_LIST, icon: 'fa-calendar-plus'}),
      Menu({name: 'Agendamentos de Carga', path: ROUTE_PATH.SCHEDULE_LIST, icon: 'fa-calendar-plus'}),
      Menu({name: 'Agendamento de Terceiros', path: ROUTE_PATH.SERVICE_PROVIDER_SCHEDULE_LIST, icon: 'fa-calendar-plus'}),
      Menu({name: 'Pessoas - Acesso Interno', path: ROUTE_PATH.INTERNAL_PERSON_LIST, icon: 'fa-calendar-plus'})

    ], roles: [Roles.ADMIN, Roles.FORNECEDOR]
  }),

  Menu({
    name: 'Configurações', path: '/configuracoes',
    icon: 'fa-stream', children: [
      Menu({name: 'Dispositivos', path: ROUTE_PATH.DEVICE_LIST, icon: 'fa-tablet-alt', roles: [Roles.SUPER]}),
      Menu({name: 'Empresas', path: ROUTE_PATH.SUPPLIER_LIST, icon: 'fa-building', roles: [Roles.SUPER]}),
      Menu({name: 'Usuários', path: ROUTE_PATH.USER_LIST, icon: 'fa-users', roles: [Roles.SUPER, Roles.ADMIN, Roles.FORNECEDOR]}),
      //Menu({name: 'Perfil de Acesso', path: ROUTE_PATH.PROFILE_LIST, icon: 'fa-id-badge', roles: [Roles.SUPER]}),
      //Menu({name: 'Roles', path: ROUTE_PATH.ROLE_LIST, icon: 'fa-lock-open', roles: [Roles.SUPER]}),
      Menu({name: 'Configuração de Integrações', path: ROUTE_PATH.INTEGRATION_CONFIG_LIST, icon: 'fa-network-wired', roles: [Roles.SUPER]}),
      Menu({name: 'Locais de Acesso', path: ROUTE_PATH.LOCAL_LIST, icon: 'fa-map-marker-alt', roles: [Roles.SUPER]}),
      Menu({name: 'Grupos de Acesso', path: ROUTE_PATH.ACCESS_PROFILE_LIST, icon: 'fa-map-marked-alt', roles: [Roles.SUPER]}),

    ], roles: []
  }),

  Menu({
    name: 'OCR', path: '/ocr',
    icon: 'fa-stream', children: [
      Menu({name: 'GATES', path: ROUTE_PATH.GATE_LIST, icon: 'fa-truck', roles: [Roles.SUPER]}),
      Menu({name: 'EVENTOS - OCR', path: ROUTE_PATH.OCR_EVENT_LIST, icon: 'fa-truck', roles: [Roles.SUPER]}),
    ], roles: [Roles.SUPER]
  }),

  // Menu({
  //   name: 'Relatórios', path: '/relatorios',
  //   icon: 'fa-cogs', children: [
  //     Menu({name: 'Pessoas por Fornecedor', path: ROUTE_PATH.PERSON_LIST, icon: 'fa-user-friends'}),
  //     Menu({name: 'Acessos por Fornecedor', path: ROUTE_PATH.PERSON_LIST, icon: 'fa-user-friends'}),
  //     Menu({name: 'Agendamento não confirmados', path: ROUTE_PATH.PERSON_LIST, icon: 'fa-user-friends'}),
  //   ], roles: [Roles.ADMIN]
  // }),

];
