import Objects from "./Objects";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default class PDFUtils {

  static convertArrayOfObjectsToPDF({ data, columns, title, lineDelimiter = '\n' }) {
    if (Objects.isNullOrUndefined(data)) {
      return null;
    }

    const cols = columns.map(x => x.Header);
    const rows = [];

    data.forEach(function (item) {
      const objectRowArray = [];
      columns.forEach(function (column) {
        if (column.renderRow) {
          //Cuidado apenas funçoes Cell que retornam tipos primitivos. string, number, boolean etc...
          objectRowArray.push(column.renderRow(item))
          return
        }

        objectRowArray.push(Objects.getValueByCustomField((column.accessor), item, column));
      });
      rows.push(objectRowArray)
    });

    var doc = new jsPDF();
    doc.setFontSize(24);
    doc.text(title, 70, 20);
    doc.autoTable(cols, rows, {margin: {top: 30}});
    doc.save(title + '.pdf');
  }
}