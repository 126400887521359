import DirectionSelector, {GATE_ACCESS_TYPES} from "./component/DirectionSelector";
import PhotoTypeSelector, {PHOTO_TYPES} from "./component/PhotoTypeSelector";

const GatesFormSchema = () => {
  const baseEndpoint = 'gtt-cflow-ocr/v1/gates/'
  return {

    getTitle(isNew) {
      return isNew ? 'Novo GATE' : 'Editar GATE';
    },

    formatObjectToSave(formData) {
      formData.direction = formData.direction?.id;
      formData.cameraList?.forEach(camera => camera.photoType = camera.photoType.id);
      return formData;
    },

    formatObjectToGet(formData) {
      formData.direction = GATE_ACCESS_TYPES.find(x => x.id === formData.direction);
      formData.cameraList?.forEach(camera => camera.photoType = PHOTO_TYPES.find(x => x.id === camera.photoType));
      return formData;
    },

    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },

    getSchema() {
      return {
        title: "Gates",
        type: "object",
        required: ["name"],
        properties: {
          name: {type: "string", title: "Nome"},
          integrationGateId: {type: "string", title: "ID da integração"},
          direction: {
            title: "Direção do acesso",
            type: 'object',
            properties: {
              id: {type: "string"},
            }
          },
          cameraList: {
            title: "Câmera",
            type: "array",
            items: {
              type: "object",
              properties: {
                name: {type: "string", title: "Nome da câmera"},
                url: {type: "string", title: "URL"},
                photoType: {
                  title: "Alvo da foto",
                  type: 'object',
                  properties: {
                    id: {type: "string", title:"Alvo da foto"},
                  }
                }
              }
            }
          }
        }
      }
    },
    getUiSchema: () => {
      return {
        direction: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<DirectionSelector disabled={props.disabled}
                                             required={props.required}
                                             title={props.title}
                                             value={props.formData}
                                             showCreateButton={false}
                                             setValue={props.onChange}/>
            );
          }
        },
        cameraList: {
          items:{
            photoType: {
              "ui:options": {
                label: false
              },
              "ui:field": (props) => {
                return (<PhotoTypeSelector disabled={props.disabled}
                                           required={props.required}
                                           title={props.title}
                                           value={props.formData}
                                           showCreateButton={false}
                                           setValue={props.onChange}/>
                );
              }
            },
          }
        }
      }
    }

}}

export default GatesFormSchema;
