import {DateRangeColumnFilter, SelectColumnFilter} from "../../../components/table/TableFilter";
import {dayMonthYearAtTime} from "../../../shared/utils/dates";
import PersonSelector from "../../person/component/PersonSelector";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import VehicleSelector from "./VehicleSelector";

const ServiceProviderScheduleListSchema = (vehicleId) => {

  const endpoint = 'gtt-access-control/v1/service-provider-schedules';

  return {
    title: 'Lista de Agendamentos de prestador de serviços',

    getEndpoint: () => `${endpoint}?vehicle=${vehicleId}&`,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.SERVICE_PROVIDER_SCHEDULE_FORM
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Prestador de Serviço",
          accessor: "person",
          Filter: SelectColumnFilter,
          CustomSelector: PersonSelector,
          Cell: ({ row }) => row.original.person.name,
        },
        {
          Header: "Veículo",
          accessor: "vehicle",
          Filter: false,
          Cell: ({ row }) => row.original.vehicle ?"Placa: " + row.original.vehicle.frontPlate : "Sem veículo cadastrado",
        },
        {
          Header: "Empresa",
          accessor: "company.name"
        },
        {
          Header: "Ajudante(s)",
          Filter: false,
          Cell: ({row}) => {
            return row.original.assistants ? row.original.assistants.map(x => <li>{x.name}</li>) : "Sem Ajudantes"
          }
        },
        {
          Header: "Data e Hora do agendamento",
          accessor: "initialDate",
          Filter: DateRangeColumnFilter,
          Cell: ({ row }) =>
            (row.original.initialDate && dayMonthYearAtTime(row.original.initialDate)) + ' até ' + (row.original.initialDate &&dayMonthYearAtTime(row.original.finishDate)),
        },
      ]
    }
  }
}

export default ServiceProviderScheduleListSchema;