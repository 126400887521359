import {BaseSelectorReadonlyToInputV2} from "../../../components";

const DriverSelector = ({
  required = false,
  defaultValue,
  title = "Motorista",
  setValue = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `gtt-access-control/v1/persons?sort=name,ASC&name=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="name"
    getUrl={getUrl}
    setValue={setValue}
    showCreateButton={false}
    {...props}
  />

}

export default DriverSelector;
