import {BaseSelectorReadonlyToInputV2} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import {useState} from "react";
import {useSelector} from "react-redux";
import {anyMath} from "../../../components/route/Routes";
import Roles from "../../../shared/routes/Roles";

const AccessProfileSelector = ({
  required = false,
  defaultValue,
  title = "Perfil de acesso",
  setValue = null,
  showCreateButton = true,
  ...props
}) => {

  const [roles, setRoles] = useState(null);
  const currentUser = useSelector(({authState}) => authState.currentUser);


  useState(() => {
    if (currentUser) {
      const {authorities} = currentUser;
      setRoles(authorities.map(x => x.authority));
    }
  }, [currentUser])

  if (!roles) {
    return null;
  }

  const getUrl = (filterValue) => {
    return `gtt-access-control/v1/access-profiles?sort=nome,ASC&nome=${filterValue}`;
  }

  return<>
    {anyMath(roles, Roles.FORNECEDOR) && !currentUser.super ?
      <></> :

      <BaseSelectorReadonlyToInputV2
        defaultValue={defaultValue}
        title={title}
        required={required}
        label="name"
        getUrl={getUrl}
        setValue={setValue}
        showCreateButton={showCreateButton}
        pathToCreate={ROUTE_PATH.ACCESS_PROFILE_FORM}
        {...props}
      />
    }
    </>
}

export default AccessProfileSelector;
