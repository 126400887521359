import {useState} from "react";
import {useSelector} from "react-redux";
import {anyMath} from "../../../components/route/Routes";
import Roles from "../../../shared/routes/Roles";



const IsInternalBlockedToSupplier = ({
  value,
  setValue = null,
}) => {

  const [checked, setChecked] = useState(value);
  const [roles, setRoles] = useState(null);
  const currentUser = useSelector(({authState}) => authState.currentUser);

  useState(() => {
    if (currentUser) {
      const {authorities} = currentUser;
      setRoles(authorities.map(x => x.authority));
    }
  }, [currentUser])

  if (!roles) {
    return null;
  }

  const _onChange = (e) => {
    setChecked(e.target.checked);
    setValue && setValue(e.target.checked);
  };


  return (
    <>
      {anyMath(roles, Roles.FORNECEDOR) ?
      <></>:
      <label>
        <input type="checkbox"
               onClick={_onChange}
               checked={checked}
        />
        <span className="mx-1"> Usuário interno </span>
      </label>
      }
    </>
  )
}

export default IsInternalBlockedToSupplier;