import React, {useEffect, useState} from 'react'
import CustomSelect from "../../../components/custom-select/CustomSelect";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";
import {useParams} from "react-router-dom";

export default function UserProfileCustomSelect({setValue, onlyId}) {

  const [profiles, setProfiles] = useState([]);
  const [user, setUser] = useState({profiles: []});
  const [isLoading, setIsLoading] = useState(true);
  let {id} = useParams();

  useEffect(() => {

    async function getAllUsers() {
      try {
        setIsLoading(true);
        setProfiles((await HttpRequest.get(`gtt-auth/v1/user-company-access/${id}/profiles?size=999999`)).content)
        setUser(await HttpRequest.get(`gtt-auth/v1/users/${id}`));
      } catch (error) {
        Notify.error('Erro ao buscar usuários', error)
      } finally {
        setIsLoading(false);
      }
    }

    getAllUsers()
  }, [id]);

  const _setValue = (e) => {
    const data = onlyId ? e.map(x => x.id) : e;
    setValue && setValue(data);
  }

  return (<CustomSelect setValue={_setValue} isLoading={isLoading} availables={profiles} selecteds={user.profiles}/>)
}
