import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const SupplierListSchema = () => {

  const endpoint = 'gtt-auth/v1/companies';

  return {
    title: 'Lista de Empresas',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.SUPPLIER_FORM_LIST,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Empresa",
          accessor: "name"
        },
        {
          Header: "CNPJ/CPF",
          accessor: "registrationNumber"
        },
        {
          Header: "Responsável",
          accessor: "companyResponsible.name"
        },
        {
          Header: "Telefone",
          accessor: "companyResponsible.phone"
        },
        {
          Header: "Email",
          accessor: "companyResponsible.email"
        },
        OptionColumn({
          endpointToDelete: endpoint,
          showDelete: false,
          editTo: ROUTE_PATH.SUPPLIER_FORM_LIST,
          showBlock:true
        })
      ]
    },
  }
}

export default SupplierListSchema;

