import {InputMaskedRegistrationNumber} from "../../components/form/InputMaskedRegistrationNumber";
import {isValidCPF} from "../../shared/utils/isValidCPF";
import AccessProfileSelector from "../access-profile/component/AccessProfileSelector";
import IsInternalBlockedToSupplier from "./component/IsInternalBlockedToSupplier";
import RegistrationTypeSelector2, {REGISTRATION_DOCUMENTS} from "./component/RegistrationTypeSelector2";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const PersonFormSchema = () => {

  const baseEndpoint = 'gtt-access-control/v1/persons/'

  return {

    getTitle(isNew) {
      return isNew ? 'Nova Pessoa' : 'Editar Pessoa';
    },

    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    redirectOnSave(history, entity, match, {currentUser}) {
      const params = new URLSearchParams(history.location.search)
      if (!params.has('wizzard')) {
        history.goBack();
        return;
      }
      if(params.has('exists')) {
        history.push(ROUTE_PATH.INTERNAL_PERSON_FORM + entity.id + '?wizzard=true')
        return;
      }
      history.push(ROUTE_PATH.PERSON_PHOTO_FORM + entity.id + '?wizzard=true')
    },

    formatObjectToSave(formData) {
      formData.arquivos = formData?.arquivos?.filter(x => !!x)
      formData.identificationDocument = {
        ...formData.registrationType,
        value: formData.registrationNumber,
      };

      if (!formData.driverLicence?.code && !formData.driverLicence?.expiryIn) {
        formData.driverLicence = null;
      }

      return formData;
    },
    formatObjectToGet(formData) {
      formData.registrationType = {
        id: formData.identificationDocument.id,
        label: formData.identificationDocument.id
      };
      formData.registrationNumber = formData.identificationDocument.value;
      return formData;
    },
    getSaveUrlV2({id}, history) {
      const params = new URLSearchParams(history.location.search)
      if (params.has('edit') || !id) {
        return `${baseEndpoint}${id || ''}`;
      }
      return `${baseEndpoint}${id}/add-access-company`;
    },

    validate(formData, errors) {
      if (formData.registrationType.id === "CPF") {
        !isValidCPF(formData?.registrationNumber)
        && errors?.registrationNumber?.addError("O CPF digitado é inválido")
      }
      return errors
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        },
        "cpf": {
          "ui:help": "CPF 000.000.000-00",
          "ui:field": (props) => {
            return (<InputMaskedRegistrationNumber title={props.schema.title}
                                                   onChangeText={props.onChange}
                                                   value={props.formData}/>
            );
          }
        },
        accessProfile: {
          "ui:field": (props) => {
            return (<AccessProfileSelector
                disabled={props.disabled}
                required={true}
                title={props.schema.title}
                value={props.formData}
                setValue={props.onChange}/>
            );
          }
        },
        driverLicence: {
          code: {
            "ui:help": "Caso seja Motorista, preencha este campo com o Número da CNH."
          },
          expiryIn: {
            "ui:help": "Caso registre CNH preencha este campo com a data de validade da CNH."
          }
        },
        internalPerson: {
          "ui:field": (props) => {
            return (<IsInternalBlockedToSupplier
                value={props.formData}
                setValue={props.onChange}/>
            );
          }
        },
        "registrationNumber": {
          "ui:help": "Número do Documento",
          "ui:field": (props) => {
            return (<InputMaskedRegistrationNumber title={props.schema.title}
                                                   mask={props.schema.mask}
                                                   onChangeText={props.onChange}
                                                   value={props.formData}
                                                   required={true}/>
            );
          }
        },
        "registrationType": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<RegistrationTypeSelector2 disabled={props.disabled}
                                               required={props.required}
                                               value={props.formData}
                                               setValue={props.onChange}/>
            );
          }
        }
      }
    },

    getSchema() {
      return {
        title: "Pessoas",
        type: "object",
        required: ["name"],
        properties: {
          approved: {},
          name: {type: "string", title: "Nome"},
          birthDate: {
            type: "string",
            title: "Data de Nascimento",
            format: "date"
          },
          accessProfile: {
            title: "Grupos de acesso",
            type: 'object',
            properties: {
              id: {type: "string", title: "Perfil de acesso"},
              name: {type: "string", title: "Perfil de acesso"}
            }
          },
          driverLicence: {
            type: 'object',
            properties: {
              code: {type: "string", title: "CNH"},
              expiryIn: {
                type: "string",
                title: "Data de Validade da CNH",
                format: "date"
              },
            }
          },
          registrationType: {
            type: "object",
            title: "Tipo de Documento",
            properties: {
              id: {
                type: 'string',
                "default": REGISTRATION_DOCUMENTS[0].id,
              },
              label: {
                type: 'string',
                "default": REGISTRATION_DOCUMENTS[0].label,
              }
            }
          }
        },
        "dependencies": {
          "registrationType": {
            "oneOf": [
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_DOCUMENTS[0].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "0",
                    title: 'CPF',
                  }
                },
              },
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_DOCUMENTS[1].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "2",
                    title: "RG"
                  }
                },
              },
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_DOCUMENTS[2].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "2",
                    title: "PASSAPORTE"
                  }
                },
              },
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_DOCUMENTS[3].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "2",
                    title: "RNE"
                  }
                },
              },
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_DOCUMENTS[4].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "2",
                    title: "OUTROS"
                  }
                },
              },
            ]
          }
        }
      };
    },
  }

}

export default PersonFormSchema;
