import {BaseSelectorReadonlyToInputV2} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const PersonSelector = ({
   required = false,
   defaultValue,
   title = "Pessoa",
   setValue = null,
   ...props
}) => {

  const mapItems = (item) => {
    return {
      ...item,
      name: (item.identificationDocument.id + ' - ' + item.identificationDocument.value + ' - ' + item.name)
    }
  }

  const getUrl = (filterValue) => {
    return `gtt-access-control/v1/persons?sort=name,ASC&documentOrName=${filterValue}`;
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="name"
    getUrl={getUrl}
    setValue={setValue}
    showCreateButton={true}
    pathToCreate={ROUTE_PATH.PERSON_EXISTS_FORM}
    mapItems={mapItems}
    {...props}
  />
}

export default PersonSelector;
