import UserProfileCustomSelect from "./componenet/UserProfileCustomSelect";

const UserPerfilFormSchema = () => {
  return {

    getTitle(isNew) {
      return 'Perfis de acesso do usuário';
    },
    getUrlFindById(id) {
      return `gtt-auth/v1/users/${id}`;
    },

    getSaveUrl(id) {
      return `gtt-auth/v1/users/${id}/profiles`;
    },

    getSchema() {
      return {
        title: "Empresa",
        type: "array",
        required: ["profiles"],
        items: {
          type: "string"
        },
      };
    },

    getUiSchema: () => {
      return {
        // "profiles": {
        "ui:options": {
          label: false
        },
        "ui:field": (props) => {
          return (<UserProfileCustomSelect disabled={props.disabled} onlyId={true} required={props.required}
                                           value={props.formData} setValue={props.onChange}/>
          );
          // }
        }
      }
    },

  }
}

export default UserPerfilFormSchema;

