import {SmallBox} from "../../../components";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import {useEffect, useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";

const VehicleCount = () => {

  const [total, setTotal] = useState('-');

  const loadTotal = async () => {
    try {
      const result = await HttpRequest.get('gtt-access-control/v1/vehicles/count');
      setTotal(result.total);
    } catch (e) {
      Notify.error('Falha ao contar veículos', e);
    }
  }

  useEffect(() => {
    loadTotal();
  }, [])

  return <SmallBox
    type="primary"
    icon="fa fa-car"
    count={total} title="Total de veículos cadastrados"
    titlebox={"Veículos"}
    navigateTo={ROUTE_PATH.VEHICLE_LIST}/>

}

export default VehicleCount;
