import OptionColumn from "../../components/table/OptionColumn";
import {formatByIdAndMatch, ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {Link} from "react-router-dom";

const InternalVehicleListSchema = () => {

  const endpoint = 'gtt-access-control/v1/vehicles?internalVehicle=true&';

  return {
    title: 'Lista de Veículos de frota',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.VEHICLE_FORM,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Placa dianteira",
          accessor: "frontPlate",
          Cell: ({row}) => <Link to={formatByIdAndMatch({id: row.original.id}, ROUTE_PATH.VEHICLE_PAGE_LIST)}>{row.original.frontPlate}</Link>
        },
        {
          Header: "Placa traseira",
          accessor: "backPlate"
        },

        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.VEHICLE_FORM,
        })
      ]
    },

  }
}

export default InternalVehicleListSchema;

