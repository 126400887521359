import ScheduleDetails from "../../schedule/component/ScheduleDetails";
import {DateRangeColumnFilter, SelectColumnFilter} from "../../../components/table/TableFilter";
import DriverSelector from "../../schedule/component/DriverSelector";
import VehicleSelector from "./VehicleSelector";
import ScheduleTypeSelector from "../../schedule/component/ScheduleTypeSelector";
import {dayMonthYearAtTime} from "../../../shared/utils/dates";
import {ROUTE_PATH} from "../../../shared/routes/RoutesPath";

const VehicleSchedule = (vehicleId) => {

  const endpoint = 'gtt-access-control/v1/schedules';

  return {
    title: 'Lista de Agendamentos',

    getEndpoint: () => `${endpoint}?vehicle=${vehicleId}&`,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.SCHEDULE_FORM,
      }
    },

    renderRowSubComponent: ({row}) => {
      return <ScheduleDetails row={row.original}  />
    },

    getColumns: () => {
      return [
        {
          Header: "Empresa",
          accessor: "company.name",
          Cell: ({ row }) => row.original.company.name,
        },
        {
          Header: "Motorista",
          accessor: "person",
          Filter:SelectColumnFilter,
          CustomSelector: DriverSelector,
          Cell: ({ row }) => row.original.person.name,
        },
        {
          Header: "Veículo",
          accessor: "vehicle",
          Filter:false,
          Cell: ({ row }) => row.original.vehicle.frontPlate,
        },
        {
          Header: "Tipo de agendamento",
          accessor:"type",
          Filter:SelectColumnFilter,
          CustomSelector:ScheduleTypeSelector,
          Cell: ({ row: { original } }) =>
            original.type ? "Entrega" : "Retirada",
        },
        {
          Header: "Carga",
          accessor:"container",
          Cell: ({ row }) => row.original.container,
        },
        {
          Header: "Data do agendamento",
          sortable: false,
          export: false,
          accessor: 'initialDate',
          Filter: DateRangeColumnFilter,
          Cell: ({ row }) =>
            (row.original.initialDate && dayMonthYearAtTime(row.original.initialDate)) + ' até ' + (row.original.initialDate &&dayMonthYearAtTime(row.original.finishDate)),
        },
        {
          Header: "Status do agendamento",
          Filter: false,
          Cell: ({ row: { original } }) => {
            if (
              original.active === false
            ) {
              return (
                <>
                  <i className="fas fa-pause"></i> Agendamento inativo.
                </>
              );
            }

            if (
              original.used === false &&
              new Date() >= new Date(original.initialDate) &&
              new Date() <= new Date(original.finishDate)
            ) {
              return (
                <>
                  <i className="fas fa-hourglass-half"></i> Agendamento pronto para uso.
                </>
              );
            }

            if (
              original.used === false &&
              new Date() > new Date(original.finishDate)
            ) {
              return (
                <>
                  <i className="far fa-calendar-times"></i> Agendamento perdido.
                </>
              );
            }

            if (original.used === true) {
              return (
                <>
                  <i className="fas fa-check"></i> Agendamento utilizado dia:{" "}
                  {original.finishDate &&
                    dayMonthYearAtTime(original.finishDate)}{" "}
                </>
              );
            }

            return (
              <>
                <i className="far fa-clock"></i>
                Agendamento ativo.
              </>
            );
          },
        },
      ]
    },
  }
}

export default VehicleSchedule;
