import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import OptionColumn from "../../components/table/OptionColumn";
import {TableButton} from "../../components/button/TableButton";

const UserListSchema = () => {

  const endpoint = 'gtt-auth/v1/users/';
  return {
    title: 'Lista de Usuários',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.USER_FORM,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "name",
        },
        {
          Header: "E-mail",
          accessor: "email",
        },
        OptionColumn({
          showDelete: false,
          showBlock: true,
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.USER_FORM,
          entity: 'Usuários',
          children: ({row}) => (
            <TableButton title="Perfis" icon="fas fa-exclamation-circle" to={`${ROUTE_PATH.USER_PERFIL}${row.original.id}`}/>
          )
        })
      ]
    },

  }
}

export default UserListSchema;

