import LocalSelector from "../local/component/LocalSelector";

const AccessProfileFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/access-profiles/'
  return {

    getTitle(isNew) {
      return isNew ? 'Novo grupo de acesso' : 'Editar grupo de acesso';
    },
    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },
    getSchema() {
      return {
        title: "Grupo de acesso",
        type: "object",
        required: ["name"],
        properties: {
          name: {type: "string", title: "Nome"},
          locals: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                id: {type: "string", title: "Locais de acesso"},
              }
            }
          }
        }
      }
    },
    getUiSchema: () => {
      return {
        locals: {
          // "ui:options": {
          //   label: false
          // },
          "ui:field": (props) => {
            return (<LocalSelector isMulti={true} disabled={props.disabled} required={props.required}
                                         title={props.title}
                                         value={props.formData} setValue={props.onChange}/>
            );
          }
        }
      }
    }

  }
}

export default AccessProfileFormSchema;

