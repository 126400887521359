import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {SelectColumnFilter} from "../../components/table/TableFilter";
import LocalSelector from "../local/component/LocalSelector";

const AccessProfileListSchema = () => {

  const endpoint = 'gtt-access-control/v1/access-profiles';

  return {
    title: 'Grupos de Acesso',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.ACCESS_PROFILE_FORM,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "name"
        },
        {
          Header: "Locais de Acesso",
          accessor: "locals.id",
          Filter: SelectColumnFilter,
          CustomSelector: LocalSelector,
          Cell: ({row}) => {
            return row.original?.locals[0]?.name ? row.original?.locals?.map(x => <li>{x.name}</li>) : 'Sem locais de acesso'
          }
        },
        OptionColumn({
          endpointToDelete: endpoint, editTo: ROUTE_PATH.ACCESS_PROFILE_FORM
        })];
    }
  }
}

export default AccessProfileListSchema;

