import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";

export const GATE_ACCESS_TYPES = [
  {id: 'IN', label: 'Entrada'},
  {id: 'OUT', label: 'Saída'},
  {id: 'BOTH', label: 'Bi-direcional'},
];

const DirectionSelector = ({
  required = false,
  title = "Direção de acesso",
  setValue = GATE_ACCESS_TYPES,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    title={title}
    required={required}
    label="label"
    setValue={setValue}
    items={GATE_ACCESS_TYPES}
    {...props}
  />
}

export default DirectionSelector;
