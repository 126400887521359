const UserFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Usuário' : 'Editar Usuário';
    },

    actionPostSave(history, entity) {
      history.replace(`/list/users-perfis/form/${entity.id}`)
    },

    getUrlFindById(id) {
      return `gtt-auth/v1/integration-users/${id}`;
    },

    getSaveUrl(id) {
      return `gtt-auth/v1/users/${id || ''}`;
    },

    formatObjectToSave(formData) {
      return formData;
    },

    formatObjectToGet(formData) {
      return formData;
    },

    validate(formData, errors) {
      if (formData.password !== formData.password2) {
        errors.password2.addError("As senhas digitas não são iguais!");
      }
      return errors;
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
          "ui:placeholder": "Fernando da Silva",
        },
        "email": {
          "ui:help": "E-mail usado para acessar o sistema e receber informações",
          "ui:placeholder": "exemplo@email.com"
        },
        password: {"ui:widget": "password"},
        password2: {"ui:widget": "password"},
      }
    },

    getSchema() {
      return {
        title: "Empresa",
        type: "object",
        required: ["name", "email", "password"],
        properties: {
          name: {type: "string", title: "Nome"},
          email: {type: "string", title: "E-mail", "format": "email"},
          password: {title: "Senha", type: "string", "minLength": 3},
          password2: {title: "Repita a senha", type: "string", "minLength": 3},
        },
      };
    }

  }
}

export default UserFormSchema;

