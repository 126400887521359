import BaseSelectorReadonlyToInputV2 from "../../../components/select/BaseSelectorReadonlyToInputV2";

export const ACCESS_TYPES = [
  {id: 'IN', label: 'Entrada'},
  {id: 'OUT', label: 'Saída'}
];

const AccessDirectionSelector = ({
  required = false,
  title = "Direção de acesso",
  setValue = ACCESS_TYPES,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    title={title}
    required={required}
    label="label"
    setValue={setValue}
    items={ACCESS_TYPES}
    {...props}
  />
}

export default AccessDirectionSelector;
