import {Page} from "../../components/page/Page";
import {Col, Row} from "react-bootstrap";
import PersonCount from "../person/component/PersonCount";
import VehicleCount from "../vehicle/component/VehicleCount";
import React from "react";
import CustomTable from "../page-list/component/CustomTable";
import {DefaultListSchema} from "../page-list/EntrityListProvider";
import ScheduleCount from "./component/ScheduleCount";
import AccessEventsListSchema from "../access-events/AccessEventsListSchema";

const listSchema = {...DefaultListSchema, ...AccessEventsListSchema()};

const ScheduleBoard = () => {
  return (
    <>
      <Page title="Dashboard">
        <Row>
          <Col sm={3} md={4}>
            <PersonCount/>
          </Col>
          <Col sm={3} md={4}>
            <ScheduleCount/>
          </Col>
          <Col sm={3} md={4}>
            <VehicleCount/>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h2 className="mb-0">Eventos de Acesso</h2>
            <CustomTable entitySchema={listSchema}/>
          </Col>
        </Row>

      </Page>
    </>

  );
}

export default ScheduleBoard;
