import {BaseSelectorReadonlyToInputV2} from "../../../components";
import {formatByIdAndMatch, ROUTE_PATH} from "../../../shared/routes/RoutesPath";
import {useHistory} from "react-router-dom";

const VehicleSidebarSelector = ({
  required = false,
  defaultValue,
  setValue = null,
  ...props
}) => {

  const history = useHistory();

  const getUrl = (filterValue) => {
    return `gtt-access-control/v1/vehicles?sort=name,ASC&frontPlate=${filterValue}`;
  }

  const redirect = (value) => {
    history.replace(formatByIdAndMatch({id: value.id}, ROUTE_PATH.VEHICLE_PAGE_LIST));
  }

  return <BaseSelectorReadonlyToInputV2
    defaultValue={defaultValue}
    required={required}
    label="frontPlate"
    getUrl={getUrl}
    placeholder={"Buscar veículo"}
    setValue={redirect}
    showCreateButton={false}
    {...props}
  />

}

export default VehicleSidebarSelector;
