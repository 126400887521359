import {dayMonthYearAtTime} from "../../shared/utils/dates";
import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {DateRangeColumnFilter, SelectColumnFilter} from "../../components/table/TableFilter";
import DriverSelector from "./component/DriverSelector";
import VehicleSelector from "./component/VehicleSelector";
import ScheduleTypeSelector from "./component/ScheduleTypeSelector";
import {TableButton} from "../../components/button/TableButton";
import ScheduleDetails from "./component/ScheduleDetails";

const ExitAuthorizationListSchema = () => {

  const endpoint = 'gtt-access-control/v1/schedules?isUsed=true&pendingAuthorize=true&';

  const endpointToAuthorize = 'gtt-access-control/v1/schedules';

  return {
    title: 'Saídas a autorizar',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.SCHEDULE_FORM,
        showNewButton: false,
      }
    },

    renderRowSubComponent: ({row}) => {
      return <ScheduleDetails row={row.original}  />
    },

    getColumns: () => {
      return [
        {
          Header: "Empresa",
          accessor: "company.name",
          Cell: ({ row }) => row.original.company.name,
        },
        {
          Header: "Motorista",
          accessor: "person",
          Filter:SelectColumnFilter,
          CustomSelector: DriverSelector,
          Cell: ({ row }) => row.original.person.name,
        },
        {
          Header: "Veículo",
          accessor: "vehicle",
          width: 90,
          minWidth: 90,
          maxWidth: 90,
          Filter:SelectColumnFilter,
          CustomSelector: VehicleSelector,
          Cell: ({ row }) => row.original.vehicle.frontPlate,
        },
        {
          Header: "Tipo de agendamento",
          accessor:"type",
          width: 130,
          minWidth: 130,
          maxWidth: 130,
          Filter:SelectColumnFilter,
          CustomSelector:ScheduleTypeSelector,
          Cell: ({ row: { original } }) =>
            original.type ? "Entrega" : "Retirada",
        },
        {
          Header: "Data do agendamento",
          sortable: false,
          export: false,
          accessor: 'initialDate',
          Filter: DateRangeColumnFilter,
          Cell: ({ row }) =>
            (row.original.initialDate && dayMonthYearAtTime(row.original.initialDate)) + ' até ' + (row.original.initialDate &&dayMonthYearAtTime(row.original.finishDate)),
        },
        {
          Header: "Status do agendamento",
          Filter: false,
          Cell: ({ row: { original } }) => {
            if (
              original.active === false
            ) {
              return (
                <>
                  <i className="fas fa-pause"/> Agendamento inativo.
                </>
              );
            }

            if (
              original.used === false &&
              new Date() >= new Date(original.initialDate) &&
              new Date() <= new Date(original.finishDate)
            ) {
              return (
                <>
                  <i className="fas fa-hourglass-half"/> Agendamento pronto para uso.
                </>
              );
            }

            if (
              original.used === false &&
              new Date() > new Date(original.finishDate)
            ) {
              return (
                <>
                  <i className="far fa-calendar-times"/> Agendamento perdido.
                </>
              );
            }

            if (original.used === true) {
              return (
                <>
                  <i className="fas fa-check"/> Agendamento utilizado dia:{" "}
                  {original.usedDate &&
                  dayMonthYearAtTime(original.usedDate)}{" "}
                </>
              );
            }

            return (
              <>
                <i className="far fa-clock"/>
                Agendamento ativo.
              </>
            );
          },
        },

        OptionColumn({
          canShowExitAuthorizationButton: ({original}) => !!(original.used && original.exitAuthorization == null),
          endpointToAuthorize: endpointToAuthorize,
          canShowEditButton: ({original}) => !original.used,
          editTo: ROUTE_PATH.SCHEDULE_FORM,
          width: 240,
          minWidth: 240,
          maxWidth: 240,
          children: ({row}) => <TableButton icon={row.isExpanded ? "fas fa-minus" : "fas fa-plus"} to={""} {...row.getToggleRowExpandedProps()} title={row.isExpanded ? 'Ver menos' : 'Detalhes'}/>
        })
      ]
    },
  }
}

export default ExitAuthorizationListSchema;
