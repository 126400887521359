import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const GateListSchema = () => {

  const endpoint = 'gtt-cflow-ocr/v1/gates';

  return {
    title: 'Lista de GATES',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.GATE_FORM,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "ID da integração",
          accessor: "integrationGateId",
        },
        {
          Header: "Nome",
          accessor: "name",
        },

        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.GATE_FORM,
        })
      ]
    },

  }
}

export default GateListSchema;

