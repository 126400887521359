export const ROUTE_PARAM = {
  COMPANY_ID: "{companyId}",
};

export const formatByIdAndMatch = ({match, id}, route) => {
  return route.replace(':id', id ? id : (match?.id));
}

export const ROUTE_PATH = {
  ACCESS_EVENTS: '/access-events',
  ROLE_LIST: `/list/roles`,
  ROLE_FORM_LIST: `/list/roles/form/`,

  DEVICE_LIST: `/list/devices`,
  FORM_LIST: `/list/devices/form/`,

  DEVICE_EVENTS_LIST: `/list/device-events?deviceId=:id`,

  ACCESS_EVENT_LIST: `/list/access-events`,

  EXIT_AUTHORIZATION: `/list/exit-authorization`,

  SUPPLIER_LIST: `/list/suppliers/`,
  SUPPLIER_FORM_LIST: `/list/suppliers/form/`,

  PERSON_LIST: `/list/persons/`,
  PERSON_FORM_LIST: `/list/persons/form/`,
  PERSON_EXISTS_FORM: `/list/person-exists/form`,
  PERSON_PROFILE_PAGE: `/persons/profile-page/:id`,
  PERSON_PROFILE_PAGE_BY_DOCUMENT: `/persons/profile-page/document/:documentNumber`,
  PERSON_PROFILE_PAGE_SCHEDULE_TAB: `/persons/profile-page/document/:documentNumber/scheduleTab/:scgEventId`,

  PERSON_PROFILE_PAGE_WIZ:`/persons/profile-page/`,
  PERSON_PENDING_LIST: `/list/pending-person`,

  INTERNAL_PERSON_LIST: `/list/internal-person/`,
  INTERNAL_PERSON_FORM: `/list/internal-person/form/`,

  APPROVE_PERSON_FORM: `/list/person-approve/form/`,

  MONITORAMENTO_VIEW: `/list/persons/`,

  PERSON_PHOTO_LIST: `/list/persons-photos/`,
  PERSON_PHOTO_FORM: `/list/persons-photos/form/`,

  PROFILE_LIST: `/list/profiles`,
  PROFILE_FORM: `/list/profiles/form/`,

  VEHICLE_LIST: `/list/vehicles/`,
  INTERNAL_VEHICLE_LIST: '/list/internal-vehicles',
  VEHICLE_FORM: `/list/vehicles/form/`,
  VEHICLE_PAGE_LIST: `/vehicles/vehicle-page/:id`,

  SCHEDULE_LIST: `/list/schedules/`,
  SCHEDULE_FORM: `/list/schedules/form/`,

  PRE_SCHEDULE_LIST: `/list/pre-schedules/`,
  PRE_SCHEDULE_FORM: `/list/pre-schedules/form/`,

  SERVICE_PROVIDER_SCHEDULE_LIST: `/list/service-provider-schedules/`,
  SERVICE_PROVIDER_SCHEDULE_FORM: `/list/service-provider-schedules/form/`,

  ACCESS_PROFILE_LIST: `/list/access-profiles/`,
  ACCESS_PROFILE_FORM: `/list/access-profiles/form/`,

  LOCAL_LIST: `/list/locals/`,
  LOCAL_FORM: `/list/locals/form/`,

  INTEGRATION_CONFIG_LIST: `/list/integration-configs/`,
  INTEGRATION_CONFIG_FORM: `/list/integration-configs/form/`,

  SCG_VIEW: `/scg-view/`,

  GATE_LIST: `/list/gates/`,
  GATE_FORM: `/list/gates/form/`,

  OCR_EVENT_LIST: `/list/ocr-events/`,

  ///----- SELF PATHS ----////
  HOME: `/`,
  DASHBOARD: `/dashboard`,
  DASHBOARD_FORNECEDOR: `/schedule-supplier`,

  OPEN_GATE_PAGE: '/event-open-gate',

  USER_LIST: `/list/users/`,
  USER_FORM: `/list/users/form/`,
  USER_PERFIL: `/list/users-perfis/form/`,

  COMPANY_LIST: `/cadastros/list/entidades/`,
  COMPANY_FORM: `/cadastros/list/entidades/form/`,

  LIST_PAGE: `/list/:entity/`,

  LIST_CADASTROS_PAGE: `/cadastros/list/:entity/`,
  FORM_CADASTROS_PAGE: `/cadastros/list/:entity/form/`,
  FORM_ID_CADASTROS_PAGE: `/cadastros/list/:entity/form/:id`,

  FORM_PAGE: `/list/:entity/form/`,
  FORM_ID_PAGE: `/list/:entity/form/:id`,

  USER_PROFILE: `/me/profile`,
};
