import {BaseSelectorReadonlyToInputV2} from "../../../components";

export const USER_TYPES = [
  {id: "true", name: "Não conferidos"},
  {id: "false", name: "Conferidos"}
];

const TypeUserSelector = ({
  required = false,
  defaultValue,
  title = "Documentos conferidos",
  setValue = USER_TYPES,
  ...props
}) => {

  return <BaseSelectorReadonlyToInputV2
    title={"Documentos"}
    required={required}
    label="name"
    setValue={setValue}
    showCreateButton={false}
    items={USER_TYPES}
    {...props}
  />

}

export default TypeUserSelector;
