import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {dayMonthYearAtTime} from "../../shared/utils/dates";
import {SelectColumnFilter} from "../../components/table/TableFilter";
import StatusSelector from "./component/StatusSelector";
import VehicleSelector from "../schedule/component/VehicleSelector";

const InternalPersonListSchema = () => {

  const endpoint = 'gtt-access-control/v1/internal-persons';

  return {
    title: 'Pessoas de Acesso Interno',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.INTERNAL_PERSON_FORM,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "name",
        },
        {
          Header: "Documento",
          accessor: "identificationDocument",
          Cell: ({row: {original}}) => {
            return original.identificationDocument ? original.identificationDocument.id + ' - ' + original.identificationDocument.value : null
          }
        },
        {
          Header: "Veículo(s)",
          accessor: "vehicle",
          Filter: SelectColumnFilter,
          CustomSelector: VehicleSelector,
          Cell: ({row}) => {
            return row.original.accessCompany?.internalAccess?.vehicles ?
              row.original.accessCompany?.internalAccess?.vehicles.map(x => <li>{x.frontPlate}</li>)
              : "Nenhum veículo cadastrado"
          }
        },
        {
          Header: "Empresa",
          accessor: "accessCompany.company.name",
          Filter: false,
        },
        {
          Header: "Status",
          accessor: "isValid",
          Filter: SelectColumnFilter,
          CustomSelector: StatusSelector,
          Cell: ({row}) => row.original?.accessCompany?.canDoInternalAccess ?
            <> <i className="fas fa-check"> </i> Permitido</> :
            <><i className="fas fa-times"> </i> Negado</>,
        },
        {
          Header: "Data de inicio",
          accessor: "accessCompany.internalAccess.initialDate",
          Filter: false,
          Cell: ({row}) => dayMonthYearAtTime(row.original.accessCompany.internalAccess.initialDate)
        },
        {
          Header: "Validade do Acesso",
          accessor: "accessCompany.internalAccess.finishDate",
          Filter: false,
          Cell: ({row}) => dayMonthYearAtTime(row.original.accessCompany.internalAccess.finishDate)
        },
        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.INTERNAL_PERSON_FORM,
          showBlock: true,
          getActiveStatus: ({original}) => !original.accessCompany?.internalAccess?.internalBlock,
        })
      ]
    },

  }
}

export default InternalPersonListSchema;

