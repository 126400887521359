import {Card, InputGroup} from "react-bootstrap";
import {AppButton, InputGroupText, InputText} from "../../../components";
import {useState} from "react";
import HttpRequest from "../../../shared/common/http/HttpRequest";
import Notify from "../../../shared/common/notify/Notify";
import {useHistory} from "react-router-dom";

const UserDetailsForm = ({currentUser}) => {

  const [name, setName] = useState(currentUser.name);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const onSubmit = async () => {
    setIsLoading(true);
    currentUser.name = name;
    try {
      await HttpRequest.put('gtt-auth/v1/users', currentUser);
      Notify.success('Usuário atualizado');
      history.push('/');
    } catch (e) {
      Notify.error('Falha ao atualizar usuário', e);
      setIsLoading(false);
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Card className="card-primary">
        <Card.Header>
          <Card.Title>Configurações</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputText defaultValue={name} id="userName" label="Nome"
                     required placeholder="Nome completo do usuário" onChangeText={setName}
          />
          <InputGroupText type="text" defaultValue={currentUser.username}
                          id="userEmail" label="E-mail de acesso" placeholder="Email do usuário"
                          disabled rightAppend={<InputGroup.Text>@</InputGroup.Text>}/>
        </Card.Body>
        <Card.Footer className="text-right">
          <AppButton type="submit" isLoading={isLoading}/>
        </Card.Footer>
      </Card>
    </form>
  )

};
export default UserDetailsForm;
