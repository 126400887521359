import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";

const LocalListSchema = () => {

  const endpoint = 'gtt-access-control/v1/locals';


  return {
    title: 'Lista de Locais de Acesso',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.LOCAL_FORM,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "name"
        },
        {
          Header: "Código de integração",
          accessor: "integrationAreaCode",
        },
        OptionColumn({
          endpointToDelete: endpoint, editTo: ROUTE_PATH.LOCAL_FORM
        })];
    }
  }
}

export default LocalListSchema;

