import UserFormSchema from "../user/UserFormSchema";
import UserPerfilFormSchema from "../user-perfil/UserPerfilFormSchema";
import DeviceFormSchema from "../devices/DeviceFormSchema";
import SupplierFormSchema from "../supplier/SupplierFormSchema";
import PersonFormSchema from "../person/PersonFormSchema";
import PersonPhotoFormSchema from "../personPhoto/PersonPhotoFormSchema";
import VehicleFormSchema from "../vehicle/VehicleFormSchema";
import ScheduleFormSchema from "../schedule/ScheduleFormSchema";
import ProfileFormSchema from "../profile/ProfileFormSchema";
import RoleFormSchema from "../role/RoleFormSchema";
import IntegrationConfigFormSchema from "../integration-config/IntegrationConfigFormSchema";
import AccessProfileFormSchema from "../access-profile/AccessProfileFormSchema";
import LocalFormSchema from "../local/LocalFormSchema";
import PersonApproveFormSchema from "../person/PersonApproveFormSchema";
import PersonExistsFormSchema from "../person/PersonExistsFormSchema";
import InternalPersonFormSchema from "../internal-schedule/InternalPersonFormSchema";
import ServiceProviderScheduleFormSchema from "../service-provider-schedule/ServiceProviderScheduleFormSchema";
import GatesFormSchema from "../gate/GateFormSchema";
import PreScheduleFormSchema from "../pre-schedule/PreScheduleFormSchema";

const entitiesSchemas = {
  'roles': RoleFormSchema,
  'users': UserFormSchema,
  'users-perfis': UserPerfilFormSchema,
  'devices': DeviceFormSchema,
  'suppliers': SupplierFormSchema,
  'persons': PersonFormSchema,
  'persons-photos': PersonPhotoFormSchema,
  'vehicles': VehicleFormSchema,
  'schedules': ScheduleFormSchema,
  'profiles': ProfileFormSchema,
  'integration-configs': IntegrationConfigFormSchema,
  'access-profiles':AccessProfileFormSchema,
  'locals':LocalFormSchema,
  'person-approve' : PersonApproveFormSchema,
  'person-exists' : PersonExistsFormSchema,
  'internal-person': InternalPersonFormSchema,
  'service-provider-schedules' : ServiceProviderScheduleFormSchema,
  'gates': GatesFormSchema,
  'pre-schedules': PreScheduleFormSchema
};

export const DefaultFormSchema = {

  getTitle(isNew) {
    return isNew ? 'Novo' : 'Editar';
  },

  getUrlFindById: null,

  formatObjectToGet(formData) {
    return formData;
  },

  formatObjectToSave(formData) {
    return formData;
  },

  validate(formData, errors) {
    console.group('Validações');
    console.log('Data to validate', formData);
    console.log('default erros ', errors);
    console.groupEnd();
    return errors;
  },

  getSchema() {
    return ({})
  },

  getUiSchema() {
    return {};
  },

  getFields() {
    return {
      TitleField: ({title, required}) => {
        return '';
      }
    };
  }
}

export const getEntityFormSchema = (entityName) => {
  const result = entitiesSchemas[entityName];
  if (!result) {
    throw new Error('Entity not found' + entityName);
  }
  return result;
}
