import {Page} from "../../components/page/Page";
import React from "react";
import PersonCount from "../person/component/PersonCount";
import {Col, Row} from "react-bootstrap";
import SuppliersCount from "../supplier/component/SuppliersCount";
import VehicleCount from "../vehicle/component/VehicleCount";
import DevicesCount from "../devices/component/DevicesCount";
import LastAccessListSchema from "../access/LastAccessListSchema";
import {DefaultListSchema} from "../page-list/EntrityListProvider";
import CustomTable from "../page-list/component/CustomTable";

const listSchema = {...DefaultListSchema, ...LastAccessListSchema()};

const DashboardPage = () => {

  return (
    <Page title="Dashboard">
      <Row>
        <Col sm={3} md={3}>
          <PersonCount/>
        </Col>
        <Col sm={3} md={3}>
          <SuppliersCount/>
        </Col>
        <Col sm={3} md={3}>
          <VehicleCount/>
        </Col>
        <Col sm={3} md={3}>
          <DevicesCount/>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <CustomTable entitySchema={listSchema}/>
        </Col>
      </Row>
    </Page>
  );

}

export default DashboardPage;
