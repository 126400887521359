import {NavDropdown} from "react-bootstrap";
import {useState} from "react";
import {Loading} from "../loading/Loading";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {Input} from "@material-ui/core";

export const NavItemCompany = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [value, setValue] = useState("")

  const loadData = async () => {
    try {
      const data = await HttpRequest.get('gtt-auth/v1/user-company-accesses');
      setData(data);
      setIsLoading(false);
    } catch (e) {
      Notify.error("Falha ao carregar entidades");
    }
  };

  const changeCompany = async (target) => {
    const {company} = target;
    console.log('company change to targget', target);
    try {
      await HttpRequest.post(`gtt-auth/v1/login/companies/${company.id}`, {})
      window.location.replace("/");
    } catch (e) {
      Notify.error('Falha ao trocar de entidade', e);
    }
  }

  return (<NavDropdown onClick={loadData}
                       title={<i title="Trocar de entidade" className="fa fa-building"/>}>
    <Loading isLoading={isLoading}/>

    <Input
      autoFocus
      className="mx-3 my-2 w-auto"
      placeholder="Digite para filtrar..."
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />

    {data.map(x => x.company.name.toLowerCase().includes(value.toLowerCase()) ? <NavDropdown.Item key={x.id} onClick={() => changeCompany(x)}>{x.company.name}</NavDropdown.Item> : <></>)}
    {/*<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
    {/*<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
    {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
    {/*<NavDropdown.Divider/>*/}
    {/*<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
  </NavDropdown>)
}
