import AccessTypeSelector from "../access/component/AccessTypeSelector";
import DeviceSelector from "../access/component/DeviceSelector";
import PersonSelector from "../person/component/PersonSelector";
import {DateRangeColumnFilter, SelectColumnFilter} from "../../components/table/TableFilter";
import {dayMonthYearAtTimeWithSeconds} from "../../shared/utils/dates";
import {formatByIdAndMatch, ROUTE_PATH} from "../../shared/routes/RoutesPath";
import {Link} from "react-router-dom";

export const AccessTypeMessageGrid = (row) => {
  return AccessTypeMessage(row.original.accessType);
}

export const AccessTypeMessage = (accessType) => {
  switch (accessType) {
    case "GRANTED":
      return "Permitido"
    case "IN_PROCESS":
      return "Aguardando dados do veículo"
    default:
      return "Negado"
  }
}

const AccessEventsListSchema = () => {
  const endpoint = 'gtt-access-control/v1/access-events';
  return {
    getEndpoint: () => endpoint,

    title: 'Eventos de Acesso',

    showPDFButton: true,

    getColumnsPDF: () => {
      return [
        {
          Header: "Data",
          accessor: "intervalDate",
          Filter: DateRangeColumnFilter,
          renderRow: (item) => dayMonthYearAtTimeWithSeconds(item?.createdAt)
        },
        {
          Header: "Documento / Pessoas",
          accessor: "person.id",
          Filter: SelectColumnFilter,
          CustomSelector: PersonSelector,
          renderRow: (item) => item.person ? item.person.identificationDocument.id + ' - '
            + item.person.identificationDocument.value + ' - ' + item.person.name : "Não identificado"
        },
        {
          Header: "Tipo de Acesso",
          accessor: "accessType",
          Filter: SelectColumnFilter,
          CustomSelector: AccessTypeSelector,
          renderRow: (item) => AccessTypeMessage(item.accessType)
        },
        {
          Header: "Local de Acesso",
          accessor: "device.id",
          Filter: SelectColumnFilter,
          CustomSelector: DeviceSelector,
          renderRow: (item) => item?.device?.name || ''
        },
      ]
    },

    getColumns: () => {
      return [
        {
          Header: "Data",
          accessor: "intervalDate",
          width: 70,
          minWidth: 70,
          maxWidth: 70,
          Filter: DateRangeColumnFilter,
          Cell: ({row}) => dayMonthYearAtTimeWithSeconds(row.original?.createdAt)
        },
        {
          Header: "Documento / Pessoas",
          accessor: "person.id",
          Filter: SelectColumnFilter,
          CustomSelector: PersonSelector,
          Cell: ({row}) => row.original.person ? <Link to={formatByIdAndMatch({id: row.original.person.id}, ROUTE_PATH.PERSON_PROFILE_PAGE)}>{row.original.person.identificationDocument.id + ' - '
            + row.original.person.identificationDocument.value + ' - ' + row.original.person.name}</Link> : "Não identificado"
        },
        {
          Header: "Tipo de Acesso",
          accessor: "accessType",
          urlValue: "accessEvent",
          width: 80,
          minWidth: 80,
          maxWidth: 80,
          Filter: SelectColumnFilter,
          CustomSelector: AccessTypeSelector,
          Cell: ({row}) => row.original.type === 'VEHICLE' && row.original.person != null ? <a href="/scg/contingent">{AccessTypeMessageGrid(row)}</a> : AccessTypeMessageGrid(row)
        },
        {
          Header: "Local de Acesso",
          accessor: "device.id",
          Filter: SelectColumnFilter,
          CustomSelector: DeviceSelector,
          Cell: ({row}) => row.original?.device?.name || null
        },
      ]
    }
  }
}

export default AccessEventsListSchema;
