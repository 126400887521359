
const LocalFormSchema = () => {
  const baseEndpoint = 'gtt-access-control/v1/locals/'
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Local de Acesso' : 'Editar Local de Acesso';
    },
    getUrlFindById(id) {
      return `${baseEndpoint}${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}${id || ''}`;
    },
    getSchema() {
      return {
        title: "Local de acesso",
        type: "object",
        required: ["name"],
        properties: {
          name: {type: "string", title: "Nome"},
          integrationAreaCode: {type: "integer", title: "Código de Integração"}
        }
      };
    }
  }
}

export default LocalFormSchema;

