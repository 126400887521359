import {Button, Card, Col, Row} from "react-bootstrap";
import {Page} from "../../components/page/Page";
import {useEffect, useState} from "react";
import IntegrationConfig from "../integration-config/component/IntegrationConfigSelector";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {Loading} from "../../components/loading/Loading";

export const EventOpenGate = () => {

  const [easygateHttpConfig, setEasyGateHttpConfig] = useState(null);
  const [gates, setGates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const openGate = (e) => {
    HttpRequest.get(`${easygateHttpConfig.url}/rest/opengate/${e.id}`, {basePath: ''})
  }

  useEffect(async () => {
    if (!easygateHttpConfig) {
      return;
    }
    try {
      setIsLoading(true);
      let formData = await HttpRequest.get(`${easygateHttpConfig?.url}/rest/gates`, {basePath: ''});
      setGates(formData)
    } catch (e) {
      Notify.error("Falha ao carregar dados", e)
    } finally {
      setIsLoading(false)
    }
  }, [easygateHttpConfig]);

  return <Page>
    <Row>
      <Col>
        <IntegrationConfig value={easygateHttpConfig} type="EASYGATE" useFirst={true} title="Easygate" setValue={(e) => setEasyGateHttpConfig(e)}/>
      </Col>
    </Row>

    <Loading isLoading={isLoading}>
      <Row>
        {gates?.map((x) => {
          return (
            <Col sm={3} key={x.name}>
              <Card>
                <Card.Header style={{backgroundColor: "#49FF00"}}>
                  <Card.Title>
                    {x.name}
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{textAlign: "center"}}>
                  <Button variant="light" onClick={() => openGate(x)} type="submit">Abrir Gate</Button>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row>
    </Loading>

  </Page>
}
