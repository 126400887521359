import React, {useCallback} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {LinkButton} from "../button/LinkButton";
import {useHistory, useLocation} from "react-router-dom";

const BaseSelectorReadonlyToInputV2 = ({
  required = false,
  items = [], label = null,
  title = '',
  filterItems = null,
  rowId = 'id',
  getUrl = null,
  setValue = null,
  value = null,
  disabled = false,
  isMulti = false,
  showCreateButton = true,
  pathToCreate = '',
  useFirst = false,
  mapItems,
  defaultValue = null,
  placeholder="-- selecione ou digite para buscar --"
}) => {

  const location = useLocation();
  const history = useHistory();
  const getOptionLabel = useCallback((row) => {
    if (typeof label === 'function') {
      return label(row);
    }
    return row[label]
  }, [label])
  const getOptionValue = useCallback((row) => row[rowId], [rowId])

  const fetchData = (input, callback) => {
    if (items && items.length > 0) {
      const itemsFiltered = items.filter(x =>
        x[label].toLowerCase().includes(input.toLowerCase())
      );
      callback(itemsFiltered);
      return;
    }

    loadData(input, callback);
  }

  const loadData = async (input, callback) => {
    let options = [];
    try {
      if (!getUrl) {
        console.warn('cannot call getUrl beacuase is null')
        return;
      }
      const data = await HttpRequest.get(getUrl(input));
      options = data.content;
      
      if (filterItems) {
        options = options.filter(filterItems);
      }

      if(mapItems){
        options = options.map(mapItems);
      }

      if (options.length > 0 && useFirst) {
        setValue(options[0])
      }

    } catch (e) {
      Notify.error('Falha ao buscar dados', e);
    } finally {
      callback(options);
    }
  }

  const _onChange = (value) => {
    setValue && setValue(value);
  };

  const onClickToAdd = (e) => {
    e.preventDefault();
    const currentPath = location.pathname + '?useLocalData=true&wizzard=true';
    history.replace(currentPath);
    history.push(pathToCreate);
    return false;
  }

  const noOp = () => {
  }

  return (<Row className="align-items-center justify-content-md-around">
      <Col md={11} sm={10} xs={10}>
        <Form.Group>
          {title && (<Form.Label>{title}{required ? '*' : ''}</Form.Label>)}
          <AsyncSelect
            isClearable
            isDisabled={disabled}
            required={required}
            cacheOptions
            defaultOptions
            value={value && Object.keys(value).length > 0 ? value : defaultValue}
            isMulti={isMulti}
            loadOptions={fetchData}
            onChange={_onChange}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            placeholder={placeholder}
          />
          {!disabled && (
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{opacity: 0, height: 0}}
              onChange={noOp}
              value={value && Object.keys(value).length > 0 ? value : ''}
              required={required}
            />
          )}
        </Form.Group>
      </Col>
      <Col md={1} sm={2} xs={2} className="text-center mt-3 ">
        {showCreateButton && <LinkButton size="md" to="" onClick={onClickToAdd}>
          +
        </LinkButton>}
      </Col>
    </Row>
  );
};

export default BaseSelectorReadonlyToInputV2;
