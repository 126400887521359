import React, {useEffect, useRef, useState} from 'react';
import {loadPlayer} from "rtsp-relay/browser";
import DeviceSelectorAll from "../modules/access/component/DeviceSelectorAll";

const DeviceWebcam = ({onLoadPhoto}) => {

  const [device, setDevice] = useState(null);
  const [ws, setWs] = useState(null);
  const canvasId = "canvasDeviceIntelbras";

  const capture = React.useCallback(
    () => {
      const canvasImg = document.getElementById(canvasId);
      const base64Image = canvasImg.toDataURL("img/jpeg");
      onLoadPhoto && onLoadPhoto(base64Image)
    },
    []
  );

  const wsType = () => {
    if (window.location.protocol === "http:") {
      setWs("ws");
    } else if (window.location.protocol === "https:") {
      setWs("wss");
    }
    return ws;
  }

  useEffect(() => {
    wsType();
  })

  useEffect(() => {

    if (!device) {
      return;
    }

    const ip = device.ip.replace('http://', '').replace('https://', '');
    const password = device.password.replace('@', '%40');
    let videoStream;

    const canvas = document.createElement('canvas');
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    canvas.style.maxWidth = "100%";
    canvas.id = canvasId;

    document.getElementById('divCanvasIntelbras').appendChild(canvas);

    loadPlayer({
      url: `${ws}://${window.location.host}/api/rtsp/stream/${ip}?password=${password}`,
      canvas: canvas,
      preserveDrawingBuffer: true
    }).then((p) => {
      videoStream = p;
    });

    return () => {
      if (videoStream) {
        videoStream.destroy()
      }
    }

  }, [device]);

  return (
    <div className="d-flex flex-column align-items-center">
      <DeviceSelectorAll defaultValue={device} setValue={device => {
        setDevice(device);
      }}/>
      {device ?
        <>
          <div style={{width: 360, height: 640}} id="divCanvasIntelbras">
          </div>
          <button className="btn btn-primary" style={{marginTop: "-55px", zIndex: '1'}} onClick={capture}>Tirar Foto</button>
        </>
        : <></>
      }
    </div>
  );
};
export default DeviceWebcam;
