import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {Form} from "@rjsf/bootstrap-4";
import {Button, Card, Col, Row} from "react-bootstrap";
import {AppButton} from "../index";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import React, {useEffect, useState} from "react";
import {getFormData, saveFormData} from "../../modules/page-form/PageFormService";
import {ArrayFieldTemplate} from "../arrayfieldtemplate/ArrayFieldTemplate";
import {useSelector} from "react-redux";

const replaceMessage = (text) => (text
    // .replace('.questionario.id is a required property', 'Questionário deve ser preenchido')
    // .replace('.questionario should be object', 'Questionário deve ser preenchido')
    // //-- setores --//
    // .replace('.responsavel should be object', 'Usuário deve ser preenchido')
    // .replace('responsavel should be object', 'Usuário deve ser preenchido')
    // .replace('.funcionarios is a required property', 'Funcionários deve ser preenchido')
    // .replace('.setores is a required property', 'Setores deve ser preenchido')
    // .replace('.responsavel.id is a required property', 'Usuário deve ser preenchido')
    // //-- Pessoas --//
    // .replace('.cargo.id is a required property', 'Cargo deve ser preenchido')
    // .replace('.cargo should be object', 'Cargo deve ser preenchido')
    // //-- Entidade --//
    // .replace('.registrationNumber should match pattern "\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}"', 'CPF deve corresponder a mascara padrão 000.000.000-00')
    // .replace('.registrationNumber should match pattern "\\d{2}\\.\\d{3}\\.\\d{3}/\\d{4}-\\d{2}"', 'CNPJ deve corresponder a mascara padrão 00.000.000/0000-00')
    // // .replace('should match pattern "\\d{2}\\.\\d{3}\\.\\d{3}/\\d{4}-\\d{2}"', '')
    // // .replace('should match pattern "\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}"', '')
    // .replace('should be array', 'deve ser preenchido')
    //
    // //-- Fluxo de dados --//
    // .replace('.setor.id is a required property', 'Setores deve ser preenchido')
    // .replace('.processoText is a required property', 'Descrição do Processo deve ser preenchido')
    // //-- Politica/Procedimentos --//
    // .replace('.tipoPolitica.id is a required property', 'Tipo de Política deve ser preenchido')
    // .replace('.politicaText is a required property', 'Política deve ser preenchido')
    // //-- Gestão de Risco --//
    // .replace('.processo.id is a required property', 'Processos deve ser preenchido')
    // .replace('.funcionario.id is a required property', 'Pessoas deve ser preenchido')
    // .replace('.categorias.id is a required property', 'Categoria deve ser preenchido')
    // //-- Perguntas --//
    // .replace('.escolhas should NOT have fewer than 2 items', 'Deve conter no minímo 2 opções de resposta')
    //
    .replace('is a required property', 'Este campo é obrigatório')
    .replace('responsavel:', 'Usuário')
    .replace('should be string', 'deve ser um texto')
    .replace('should NOT have fewer than 2 items',
        'Deve conter no minímo 2 opções')
    .replace('should be object', 'Este campo é obrigatório')
    .replace('should be integer', 'O numero deve ser inteiro')

)

const replaceStack = (text) => text;

const replaceProperty = (text) => {
  return text
  .replace('.funcionarios', 'Usuário')
  .replace('.responsavel', 'Pessoas')
  .replace('.setor', 'Setor')
  .replace('.tipoPrestador', 'Tipo de Prestador')
  .replace('.escolhas', 'Escolhas')
  .replace('.cargo', 'Cargo')
  .replace('.arquivo', 'Arquivo')
  .replace('.assinaturas', 'Assinaturas')
  .replace('.logo', 'Logo')
  .replace('.titulo', 'Título')
  .replace('.curso', 'Curso')
  .replace('.durationInMounths', 'Duração em Meses')
};

const transformErrors = (errors) => {
  let data = errors.map(error => {
    console.group('Tradução');

    let message = replaceMessage(error.message);
    let stack = replaceProperty(replaceMessage(error.stack));

    console.log('Message: Traduzindo ', error.message, ' -> ', message);
    console.log('Stack: Traduzindo ', error.stack, ' -> ', stack);
    console.groupEnd();

    error.message = message;
    error.stack = stack;
    return error;
  });
  return data;
}

const CustomForm = ({
  entityName,
  defaultValue,
  entitySchema,
  disabled,
  originFormData
}) => {

  const history = useHistory();
  const useMatch = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(defaultValue);
  let {id} = useParams();
  const currentUser = useSelector(({authState}) => authState.currentUser);

  useEffect(() => {

    if (entityName === "persons" && !id) {
      setFormData(getFormData("person-exists", {}))
    }

  }, [])

  const onSave = (history, newEntity) => {
    if (entitySchema.redirectOnSave) {
      entitySchema.redirectOnSave(history, newEntity, useMatch, {
        currentUser
      });
    } else {
      history.goBack();
    }
  }

  const doPost = async (entity, history) => {
    try {
      const _id = id || entity.id;
      setIsLoading(true);
      setFormData(entity);
      let postUrl;
      if (entitySchema.getSaveUrl) {
        postUrl = entitySchema.getSaveUrl(_id);
      } else if (entitySchema.getSaveUrlV2) {
        postUrl = entitySchema.getSaveUrlV2({...entity, _id: _id}, history);
      }

      if (entityName === "internal-person") {
        postUrl = entitySchema.getSaveUrl(entity.person.id);
        return await doPut(postUrl, entity)
      }

      let isNew = entitySchema.isNew ? entitySchema.isNew(entity) : !_id;
      if (!isNew) {
        return await doPut(postUrl, entity);
      }

      const newEntity = await HttpRequest.post(postUrl, entity);

      if (entitySchema.personDontExists) {
        if (newEntity[0]) {
          if (newEntity[0].deleted === true) {
            Notify.error(
              `Pessoa: ${newEntity[0].name} foi deletada por um administrador. Se deseja adicioná-la entre em contato com a administração.`
            )
          } else {
            entitySchema.actionPostSave(history, newEntity[0])
            Notify.success(
              'Pessoa já cadastrada, clique em salvar para adiciona-lá!');
          }
        } else {
          entitySchema.personDontExists(history)
          Notify.success(
              'Pessoa não cadastrada, por favor realize o cadastro!');
        }

      } else {
        entitySchema.actionPostSave ? entitySchema.actionPostSave(history,
            newEntity) : onSave(history, newEntity)
        Notify.success('Registro criado com sucesso!');
      }

    } catch (e) {
      Notify.error('Falha ao salvar', e);
    } finally {
      setIsLoading(false);
    }
  }

  const doPut = async (url, entity) => {
    try {
      await HttpRequest.put(url, entity);
      Notify.success('Registro alterado com sucesso!');
      if (entitySchema.goNewPage) {
        entitySchema.goNewPage(history, entity);
        return;
      }
      onSave(history, entity)
    } catch (e) {
      Notify.error('Falha ao salvar', e);
    }
  }

  const onSubmit = (evt) => {
    let eventToSave = evt.formData;
    eventToSave = removeOptionalEmptyValues(eventToSave);
    if (entitySchema.formatObjectToSave) {
      eventToSave = entitySchema.formatObjectToSave(eventToSave,
          originFormData);
    }
    doPost(eventToSave, history)
  }

  const removeOptionalEmptyValues = (data) => {
    for (let field in data) {
      let value = data[field];
      if (!value) {
        continue;
      }
      if (typeof value !== 'object') {
        continue;
      }
      let isEmpty = Object.keys(value).length === 0;
      if (isEmpty) {
        data[field] = undefined;
      }
    }
    return data;
  }

  const onChange = (e) => {
    //console.log('e.formData', e.formData);
    const data = removeOptionalEmptyValues(e.formData);
    saveFormData(entityName, data);
  }

  const schema = entitySchema.getSchema();

  return <Form disabled={disabled || isLoading}
               schema={schema}
               showErrorList={false}
               transformErrors={transformErrors}
               formData={formData}
               validate={entitySchema.validate}
               ArrayFieldTemplate={ArrayFieldTemplate}
               uiSchema={entitySchema.getUiSchema()}
               fields={entitySchema.getFields()}
               onChange={onChange}
               onSubmit={onSubmit}>
    <Card.Footer style={{margin: '-1.25rem'}}>
      <Row>
        <Col>
          <Button disabled={disabled || isLoading} variant="light" type="reset"
                  onClick={history.goBack}>Cancelar</Button>
        </Col>
        <Col className="text-right">
          {entitySchema.submitButton ? entitySchema.submitButton() :
              <AppButton type="submit" title={entitySchema.submitButtonTitle}
                         isLoading={disabled || isLoading}/>}
        </Col>
      </Row>
    </Card.Footer>
  </Form>;

}

export default CustomForm;
