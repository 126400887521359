import {useEffect, useState} from "react";
import {BaseSelectorReadonlyToInputV2} from "../index";

export const PAGINATION_OPTIONS = [
  {id: 5, label: '5 itens por página'},
  {id: 10, label: '10 itens por página'},
  {id: 15, label: '15 itens por página'},
  {id: 20, label: '20 itens por página'},
  {id: 30, label: '30 itens por página'},
  {id: 50, label: '50 itens por página'},
  {id: 100, label: '100 itens por página'}
];

const TablePageSelector = ({
  required = false,
  title = "Itens por Pagínas",
  setValue = null,
  value,
  ...props
}) => {

  const [_value, setInternalValue] = useState(PAGINATION_OPTIONS.filter(x => x.id === value)[0])

  const _setValue = (value) => {
    setValue && setValue(value);
    setInternalValue(value);
  }

  useEffect(() => {
    setInternalValue(PAGINATION_OPTIONS.filter(x => x.id === value)[0]);
  }, [value])

  return <BaseSelectorReadonlyToInputV2
    showCreateButton={false}
    showTitle={false}
    title={title}
    required={required}
    label="label"
    value={_value}
    setValue={_setValue}
    items={PAGINATION_OPTIONS}
    {...props}
  />
}

export default TablePageSelector;
