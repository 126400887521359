import {useRouteMatch} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {DefaultListSchema} from "../page-list/EntrityListProvider";
import HttpRequest from "../../shared/common/http/HttpRequest";
import {Loading} from "../../components/loading/Loading";
import {Page} from "../../components/page/Page";
import {Card, Col, Row} from "react-bootstrap";
import CustomTable from "../page-list/component/CustomTable";
import InternalAccessVehicle from "./component/InternalAccessVehicle";
import PropertyAndValueText from "../../components/text/PropertyAndValueText";
import VehicleServiceSchedules from "./component/VehicleServiceSchedules";
import VehicleSchedule from "./component/VehicleSchedule";

const VehicleListPage = () => {

  const match = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const [vehicle, setVehicle] = useState(null);
  const vehicleInternalAccess = useMemo(() => ({...DefaultListSchema, ...InternalAccessVehicle(match.params.id)}), [vehicle]);
  const vehicleServiceSchedules = useMemo(() => ({...DefaultListSchema, ...VehicleServiceSchedules(match.params.id)}), [vehicle]);
  const vehicleSchedules = useMemo(() => ({...DefaultListSchema, ...VehicleSchedule(match.params.id)}), [vehicle]);

  const listStyle = {
    border: "2px solid #343a40",
    borderRadius: "3px"
  }

  useEffect(async () => {
    // buscar a pessoa pelo id q veio na url
    const data = await HttpRequest.get(`gtt-access-control/v1/vehicles/${match.params.id}`)
    setVehicle(data);
    setIsLoading(false)
  }, [match.params.id]);

  if (isLoading) {
    return <Loading isLoading={isLoading}/>
  }

  return <Page>
    <Card>
      <Card.Header className={"bg-dark"}><h3>Informações do veículo - {vehicle.frontPlate}</h3>
      </Card.Header>
      <Card.Body style={{border: "2px solid #343a40 "}}>
        <Col>
          <Row>
            <PropertyAndValueText property="Veículo de frota:" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={vehicle.internalVehicle ?
              <><i className="fas fa-check"> </i> Sim</> :
              <><i className="fas fa-times"> </i> Não</>
            }/>
            <PropertyAndValueText property="Placa frontal:" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={vehicle.frontPlate}/>
            <PropertyAndValueText property="Placa traseira:" containerClassName="mb-3" propertyClassName="w-100 justify-self-end" value={vehicle.backPlate ?
              vehicle.backPlate : vehicle.frontPlate
            }/>
          </Row>
        </Col>
        <Col style={listStyle} className={'mb-3'}>
          <h2 className={'m-2'}>Lista de acesso interno do veículo</h2>
          <CustomTable entitySchema={vehicleInternalAccess}/>
        </Col>
        <Col style={listStyle} className={'mb-3'}>
          <h2 className={'m-2'}>Lista de Agendamentos de terceiros do veículo</h2>
          <CustomTable entitySchema={vehicleServiceSchedules}/>
        </Col>
        <Col style={listStyle} className={'mb-3'}>
          <h2 className={'m-2'}>Lista de Agendamentos de carga do veículo</h2>
          <CustomTable entitySchema={vehicleSchedules}/>
        </Col>
      </Card.Body>
    </Card>
  </Page>

}

export default VehicleListPage;