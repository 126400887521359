import {dayMonthYearAtTime} from "../../shared/utils/dates";
import OptionColumn from "../../components/table/OptionColumn";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import HttpRequest from "../../shared/common/http/HttpRequest";
import Notify from "../../shared/common/notify/Notify";
import {DateRangeColumnFilter, SelectColumnFilter} from "../../components/table/TableFilter";
import DeviceTypeSelector from "./component/DeviceTypeSelector";
import {TableButton} from "../../components/button/TableButton";

const DeviceListSchema = () => {

  const endpoint = 'gtt-access-control/v1/devices';

  const sendConfig = async (id, tableProps) => {
    try {
      await HttpRequest.post(`${endpoint}/${id}/send-configurations`)
      Notify.success("Configurações enviadas!")
      tableProps.setGlobalFilter(new Date());
    } catch (e) {
      Notify.error('Falha ao configurar device', e);
    }
  }

  const resetDevice = async (id) => {
    try {
      await HttpRequest.get(`${endpoint}/reset/${id}`)
      Notify.success("Usuários removidos com sucesso. Dentro de instantes o dispositivo será atualizado com os dados do sistema")
    } catch (e) {
      Notify.error('Falha ao remover os dados', e);
    }
  }

  return {
    title: 'Lista de Dispositivos',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.FORM_LIST,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "Nome",
          accessor: "name"
        },
        {
          Header: "IP",
          accessor: "ip"
        },
        {
          Header: "Tipo",
          accessor: "deviceType",
          Filter: SelectColumnFilter,
          CustomSelector: DeviceTypeSelector,
          sortable: false,
          export: false,
          Cell: ({row}) => {
            return row.original?.deviceTypeObject?.name || null
          }
        },
        {
          Header: "Criado em",
          sortable: false,
          export: false,
          Filter: DateRangeColumnFilter,
          Cell: ({row}) => row.original?.createdAt ? dayMonthYearAtTime(row.original?.createdAt) : null
        },
        {
          Header: "Ultima sincronização de pessoas",
          accessor: "lastSyncDate",
          Filter: false,
          Cell: ({row}) => row.original?.lastSyncDate ? dayMonthYearAtTime(row.original.lastSyncDate) : null
        },
        {
          Header: "Ultima sincronização de fotos",
          accessor: "photoLastSyncDate",
          Filter: false,
          Cell: ({row}) => row.original?.photoLastSyncDate ? dayMonthYearAtTime(row.original.photoLastSyncDate) : null
        },
        OptionColumn({
          minWidth:300,
          maxWidth:300,
          endpointToDelete: endpoint, editTo: ROUTE_PATH.FORM_LIST,
          showEvents: true,
          children: (props) => <TableButton icon="fas fa-cog" variant='secondary'
                                            text="Enviar configurações para o device" title="Configurar"
                                            onClick={() => sendConfig(props.row.original?.id, props)}/>,
          resetDevice: (props) => <TableButton icon="fas fa-adjust" variant='secondary'
                                               text="Remover todos os usuários do device" title="Reset"
                                               onClick={() => resetDevice(props.row.original?.id)}/>

        })
      ]
    },

  }
}

export default DeviceListSchema;

