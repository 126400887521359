import {dayMonthYear} from "./dates";

export function FormatPdfData(data) {
  data.forEach(item => {
    item.enable = item.enable ? "Ativo" : "Inativo";
    item.approved = item.approved ? item.approved.name : "Não Conferido";

    if (item.accessCompanies && item.accessCompanies.length > 0) {
      item.canDoInternalAccess = item.accessCompanies.map(x => x.canDoInternalAccess ? x.company?.name + "(Liberado)": x.company?.name + "(Bloqueado)").join(', ');
      item.accessCompaniesNames = item.accessCompanies.map(x => x.company?.name).join(', ');
      item.validadeAcessoInterno = item.accessCompanies.map(x => x.internalAccess ? x.company?.name +" - "+ dayMonthYear(x.internalAccess?.finishDate) : "-").join(';');
    } else {
      item.canDoInternalAccess = item.accessCompany.canDoInternalAccess ? "Liberado": "Bloqueado";
      item.validadeAcessoInterno = item.accessCompany.internalAccess ?
        dayMonthYear(item.accessCompany.internalAccess?.initialDate) +" - "+ dayMonthYear(item.accessCompany.internalAccess?.finishDate): "-";
      item.accessCompaniesNames = item.accessCompany.company?.name;
    }
  });

  return data;
}