import {Link, useHistory} from "react-router-dom";
import Logo from "../logo/Logo";
import {CurrentUser} from "./user/CurrentUser";
import {useEffect} from "react";

import $ from 'jquery';
import {SidebarItem} from "./SidebarItem";
import VehicleSidebarSelector from "../../modules/vehicle/component/VehicleSidebarSelector";
import PersonSelector from "../../modules/person/component/PersonSelector";
import {formatByIdAndMatch, ROUTE_PATH} from "../../shared/routes/RoutesPath";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
export const Sidebar = () => {

  const history = useHistory();

  const redirect = (value) => {
    history.replace(formatByIdAndMatch({id: value.id}, ROUTE_PATH.PERSON_PROFILE_PAGE));
  }

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
  }, []);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4" id="side-bar" style={styles.sidebarContainer}>
      <Link to="/" className="brand-link text-center">
        <Logo className="elevation-3" sidebar={true}/>
      </Link>
      <CurrentUser/>
      <div style={{marginLeft:"5px", fontSize:"14px"}}>
      <PersonSelector setValue={redirect} title={null} showCreateButton={false} placeholder={"Buscar pessoa"}
      />
      <VehicleSidebarSelector/>
      </div>
      <nav>
        <ul className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false">
          <SidebarItem/>
        </ul>
      </nav>
    </aside>
  )
}

const styles = {
  sidebarContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'fixed',
    height: '100vh'
  }
}
