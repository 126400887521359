import OptionColumn from "../../components/table/OptionColumn";
import { SelectColumnFilter } from "../../components/table/TableFilter";
import {ROUTE_PATH} from "../../shared/routes/RoutesPath";
import CompanySelector from "./component/CompanySelector";

const IntegrationConfigListSchema = () => {

  const endpoint = 'gtt-access-control/v1/integration-configs';

  return {
    title: 'Lista de configurações de integração',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        linkNewUrl: ROUTE_PATH.INTEGRATION_CONFIG_FORM,
      }
    },

    getColumns: () => {
      return [
        {
          Header: "URL",
          accessor:"url",
          Cell: ({row}) => row.original.url
        },
        {
          Header: "Método HTTP",
          Filter: false,
          Cell: ({row}) => row.original.httpMethod
        },
        {
          Header: "Empresa",
          accessor:"company.id",
          Filter:SelectColumnFilter,
          CustomSelector:CompanySelector,
          Cell: ({row}) => row.original.company.name
        },

        OptionColumn({
          endpointToDelete: endpoint,
          editTo: ROUTE_PATH.INTEGRATION_CONFIG_FORM,
        })
      ]
    },
  }
}

export default IntegrationConfigListSchema;

