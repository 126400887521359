import {Col, Image} from "react-bootstrap";
import {Card} from "@material-ui/core";
import {useEffect, useState} from "react";
import HttpRequest from "../../shared/common/http/HttpRequest";

const EventDetails = ({row}) => {
  console.log("EventDetail: ", row);

  const[image, setImage] = useState(null)

  useEffect(() => {
    base64();
  }, []);

    const base64 = async () => {
    let response = await HttpRequest.get(`gtt-cflow-ocr/v1/events/images/${row?.id}`);
    setImage(response[0]) ;
    }

  if (!row) {
    return <></>
  }

  return (
      <Card className="mb-5">
        {row.photos.map(x =>
          <Col className={"text-center my-4"}>
            <Image alt={"Imagem do veículo"} width={700} src={"data:image/png;base64," + image} />
            <Col className="mt-2">
              <p>VALORES RECONHECIDOS: {x.recognitions?.map(y => <p className="mx-2">{y.value == "" ? "****" : y.value }</p>)} </p>
            </Col>
          </Col>

        )}

      </Card>


  )
}

export default EventDetails;